import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate, useSearchParams } from 'react-router-dom';


interface IProductDetailsContext {
    isLoading: boolean
    productDetails: IProductDetails
    currentTab: string
    selectedImage: IMedia | null

    changeTab: (newTab: string) => void
    handleSelectedImage: (media: IMedia | null) => void
}

interface IProductDetails {
    brand: string
    bulletPoint1: string
    bulletPoint2: string
    bulletPoint3: string
    bulletPoint4: string
    bulletPoint5: string
    countryOfOriginCode: string
    countryOfOriginDescription: string
    currency: string
    ean: string
    isMandatoryQRCode: string
    itemId: string
    itemNumber: string
    lastUpdatedBy: string
    lastUpdatedOn: moment.Moment
    longDescriptions: string
    m3Status: string
    mainImageId: string
    price: number
    shortDescriptions: string
    upc: string


    relatedItems: IRelatedItem[]
    manuals: IMedia[]
    videos: IMedia[]
    images: IMedia[]
    spareParts: IMedia[]
    specifications: ISpecification[]
    marketingTools: IMMarketingTool[]
    warranty: {
        description: string
        title: string
    }
}

interface IRelatedItem {
    itemId: string
    itemNumber: string
}

interface IMedia {
    fileName: string
    fileSize: string
    mediaId: string
    title: string
}

interface ISpecification {
    value: string
    label: string
    unit: string
}

interface IMMarketingTool {
    title: string
    brand: string
    date: moment.Moment
}

export let productDetailsContext = React.createContext({} as IProductDetailsContext);

let { Provider } = productDetailsContext;

let ProductDetailsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [searchParams] = useSearchParams();

    const sku = searchParams.get('sku');


    let [currentTab, setCurrentTab] = useState<string>('');
    let [isLoading, setLoading] = useState(true);

    let [productDetails, setProductDetails] = useState({} as IProductDetails);
    let [selectedImage, setSelectedImage] = useState<IMedia | null>(null);


    if (!sku)
        <Navigate to="/products" />


    useEffect(() => {
        getProductDetails();
    }, [])


    let getProductDetails = async () => {
        try {
            setLoading(true);

            let res = await axios.get('/api/VisualPriceList/GetProduct', { params: { sku } });
            let { item } = res.data;

            setProductDetails(item);
            setSelectedImage(item.images[0] ?? null);

        } catch (err: any) { }
        finally {
            setLoading(false);
        }
    }

    let changeTab = (newTab: string) => {
        setCurrentTab(newTab)
    }

    let handleSelectedImage = (media: IMedia | null) => {
        setSelectedImage(media);
    }


    return (
        <Provider value={{
            isLoading,
            productDetails,
            currentTab,
            selectedImage,

            changeTab,
            handleSelectedImage
        }}>
            {children}
        </Provider>
    );
}

export default ProductDetailsProvider;