import { useContext } from "react";
import { SearchOutlined } from "@mui/icons-material";
import { Button, Grid, TextField } from "@mui/material";
import { FilterContainer } from "components";
import { adminUsersContext } from "../AdminUsersContext";

let Filters = () => {
    let service = useContext(adminUsersContext);

    return (
        <FilterContainer>
            <Grid item xs={2}>
                <TextField
                    fullWidth
                    size='small'
                    variant="standard"
                    label="Search by name or email"
                    value={service.search.searchText}
                    onChange={(e: any) => service.updateSearch({
                        ...service.search,
                        searchText: e.target.value
                    })}
                />
            </Grid>
            <Grid item>
                <Button type="submit" variant="contained" onClick={() => service.doSearch(service.search)}>
                    <SearchOutlined />
                </Button>
            </Grid>
        </FilterContainer>
    )
}

export default Filters;