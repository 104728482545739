import itIT from './itIT';
import deDE from './deDE';
import enGB from './enGB';
import enUS from './enUS';
import frFR from './frFR';
import jaJP from './jaJP';
import zhCN from './zhCN';

let Localizations: any = {
    "it-IT": itIT,
    "de-DE": deDE,
    "en-GB": enGB,
    "en-US": enUS,
    "fr-FR": frFR,
    "ja-JP": jaJP,
    "zh-CN": zhCN
}

export default Localizations;