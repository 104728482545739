import ProductData from "./ProductData";
import ProductDataProvider from "./ProductDataContext"


let Index = () => {

    return (
        <ProductDataProvider>
            <ProductData />
        </ProductDataProvider>
    );

}

export default Index;