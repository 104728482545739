import React, { useEffect, useState } from 'react';
import amplifyConf from './amplifyConf';
import { Amplify, Hub, Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';

Amplify.configure(amplifyConf.CONFIG);

interface IAuthContext {
    isLoading: boolean
    isAuthenticated: boolean
    isLoginOpen: boolean

    signInWithAd: (returnUrl?: string) => Promise<void>
    signInWithCredentials: (clientId: string, clientSecret: string) => Promise<string>
    signInAndChangePassword: (clientId: string, clientSecret: string, newPassword: string) => Promise<boolean>
    forgotPassword: (email: string) => Promise<boolean>
    forgotPasswordSubmit: (clientId: string, code: string, password: string) => Promise<void>
    signOut: () => Promise<void>
    getToken: () => Promise<string | null>
    openLogin: () => void
    closeLogin: () => void 
}

export const authContext = React.createContext({} as IAuthContext);

const { Provider } = authContext;

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();
    const location = useLocation();

    const [isLoading, setLoading] = useState(true);
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [isLoginOpen, setLoginOpen] = useState(false);


    useEffect(() => {
        getAuthenticatedUser();
    }, [])


    Hub.listen("auth", async ({ payload: { event, data } }) => {
        switch (event) {
            // Videndum access
            case "customOAuthState":
                await getAuthenticatedUser();

                const decodedUrl = decodeURIComponent(data);
                if (decodedUrl) {
                    navigate(decodedUrl);
                } else
                    navigate('/');

                setLoading(false);
                break;

            // External user access
            case "signIn":
                await getAuthenticatedUser();

                let returnUrl = '/';

                if (location.search) {
                    let url = new URLSearchParams(location.search).get("returnUrl");
                    if (url)
                        returnUrl = url;
                }

                navigate(returnUrl);
                closeLogin();
                setLoading(false);
                break;

            case "forgotPassword":
                navigate("/account/resetpassword", { state: { email: data.username } });
                break;

            case "forgotPasswordSubmit":
                navigate('/');
                closeLogin();
                break;

            case "tokenRefresh":
                await getAuthenticatedUser();
                setLoading(false);
                break;

            case 'signIn_failure':
                // enqueueSnackbar(`Error during signin`, { variant: 'error' });
                break;

            case 'oAuthSignOut':
            case "signOut":
                navigate('/');
                closeLogin();
                setAuthenticated(false);
                setLoading(false);
                break;

            default:
                setAuthenticated(false);
                setLoading(false);
                console.info(`Not managed event: ${event}`);
                break;
        }
    });


    let signInWithAd = async (returnUrl?: string) => {
        try {
            await Auth.federatedSignIn({ customProvider: 'azuread', customState: returnUrl });
        } catch (err: any) {
            enqueueSnackbar(err.message, { variant: 'error' });
        }
    }

    let signInWithCredentials = async (clientId: string, clientSecret: string) => {
        try {
            let cognitoUser = await Auth.signIn(clientId, clientSecret);

            if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
                navigate('/account/changepassword', { replace: true, state: { clientId, clientSecret } });
            }

            return 'true';

        } catch (err: any) {
            return err.message;
        }
    }

    let signInAndChangePassword = async (clientId: string, clientSecret: string, newPassword: string) => {
        try {
            let cognitoUser = await Auth.signIn(clientId, clientSecret);
            await Auth.completeNewPassword(cognitoUser, newPassword);
            return true;

        } catch (err: any) {
            enqueueSnackbar(err.message, { variant: 'error' });
            return false;
        }
    }

    // To initiate the process of verifying the attribute like 'phone_number' or 'email'
    let forgotPassword = async (userId: string) => {
        try {
            await Auth.forgotPassword(userId);
            enqueueSnackbar("Verification code has been sent to your email", { variant: 'success' });
            return true;

        } catch (err: any) {
            enqueueSnackbar(err.message, { variant: 'error' });
            return false;
        }
    }

    // To verify attribute with the code
    let forgotPasswordSubmit = async (clientId: string, code: string, password: string) => {
        try {
            await Auth.forgotPasswordSubmit(clientId, code, password);
            enqueueSnackbar("Your password has been changed", { variant: 'success' });

        } catch (err: any) {
            enqueueSnackbar(err.message, { variant: 'error' });
        }
    }

    let signOut = async () => {
        try {
            setLoading(true);
            await Auth.signOut();
        } catch (err) {
            console.error(err);
        }
    }

    let getToken = async () => {
        try {
            let session = await Auth.currentSession();

            if (session.isValid())
                return session.getIdToken().getJwtToken();
            else
                return null;

        } catch (err) {
            console.error(err);
            return null;
        }
    }

    let getAuthenticatedUser = async () => {
        try {
            setLoading(true);

            await Auth.currentAuthenticatedUser();
            setAuthenticated(true);

        } catch (err) {
            console.error(err);
            setAuthenticated(false);
        } finally {
            setLoading(false);
        }
    }

    const openLogin = () => {
        setLoginOpen(true);
    }

    const closeLogin = () => {
        setLoginOpen(false);
    }


    return (
        <Provider
            value={{
                isLoading,
                isAuthenticated,
                isLoginOpen,

                signInWithAd,
                signInWithCredentials,
                signInAndChangePassword,
                forgotPassword,
                forgotPasswordSubmit,
                signOut,
                getToken,
                openLogin,
                closeLogin
            }}
        >
            {children}
        </Provider>
    )
}

export default AuthProvider;