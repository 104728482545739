import axios from 'axios';
import React, { useState } from 'react';

interface IProductDataContext {
    selectedLanguage: string
    languages: ILanguage[]

    updateSelectedLanguage: (language: string) => void
    downloadProductData: (type: string, brand: string, lang: string, format: string) => Promise<void>
}

interface ILanguage {
    isocode: string
    description: string
    flag: string
    isDefault: boolean
}

export interface IProductData {
    productDataId: string
    brand: string
    title: string
    extension: string
    date: moment.Moment
    IsMedia: boolean
    mainMediaId: string
}

export let productDataContext = React.createContext({} as IProductDataContext);

let { Provider } = productDataContext;

let ProductDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let languages: ILanguage[] = [
        { isocode: 'en-GB', description: 'English', flag: 'gb', isDefault: true },
        { isocode: 'en-US', description: 'English (USA)', flag: 'us', isDefault: false },
        { isocode: 'it-IT', description: 'Italian', flag: 'it', isDefault: false },
        { isocode: 'de-DE', description: 'German', flag: 'de', isDefault: false },
        { isocode: 'fr-FR', description: 'French', flag: 'fr', isDefault: false },
        { isocode: 'ja-JP', description: 'Japanese', flag: 'jp', isDefault: false },
        { isocode: 'zh-CN', description: 'Chinese', flag: 'cn', isDefault: false },
        { isocode: 'zh-HK', description: 'Chinese (Hong Kong)', flag: 'hk', isDefault: false },
        { isocode: 'es-ES', description: 'Spanish', flag: 'es', isDefault: false },
        { isocode: 'es-MX', description: 'Spanish (Mexico)', flag: 'mx', isDefault: false },
        { isocode: 'ko-KR', description: 'Korean', flag: 'kr', isDefault: false },
        { isocode: 'pt-PT', description: 'Portuguese', flag: 'pt', isDefault: false },
        { isocode: 'ru-RU', description: 'Russian', flag: 'ru', isDefault: false }
    ]


    let [selectedLanguage, setSelectedLanguage] = useState<string>('en-GB');

    let updateSelectedLanguage = (language: string) => {
        setSelectedLanguage(language);
    }

    let downloadProductData = async (type: string, brand: string, lang: string, format: string) => {
        try {
            let request = {
                type,
                brand,
                lang,
                format
            }

            let res = await axios.get('/api/ProductData/DownloadProductData', { params: request });

            window.open(res.data);

        } catch (err) { }
    }


    return (
        <Provider
            value={{
                languages,
                selectedLanguage,
                updateSelectedLanguage,
                downloadProductData
            }}
        >
            {children}
        </Provider>
    )
}

export default ProductDataProvider;