import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { Outlet } from "react-router-dom";

let AuthLayout = () => {
    return (
        <Container maxWidth="xl">
            <Grid container>
                <Grid item xs>
                    <Outlet />
                </Grid>
                <Grid item xs>
                    <img src='/images/home_bg.png' width='100%' />
                </Grid>
            </Grid>
        </Container>
    )
}

export default AuthLayout;