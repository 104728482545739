import { colors, Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import './scss/main.scss';

const primary = '#e6ac00';
const spacing = 8;

const theme = createTheme({
    spacing: spacing,
    typography: {
        fontFamily: 'Open Sans',
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    color: 'black',
                    zIndex: 2
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                text: {
                    color: 'black',
                    backgroundColor: 'inherit',
                    textTransform: 'inherit',
                    ':hover': {
                        textDecoration: 'underline',
                        backgroundColor: 'inherit'
                    }
                },
                contained: {
                    color: 'white',
                    backgroundColor: 'black',
                    textTransform: 'uppercase',
                    fontSize: '1rem'
                },
                containedPrimary: {
                    color: 'white',
                    backgroundColor: 'black',
                    textTransform: 'uppercase',
                    fontSize: '1rem',
                    ':hover': {
                        backgroundColor: 'black',
                    }
                },
                outlined: {
                    color: 'black',
                    border: '1px solid black',
                    backgroundColor: 'transparent',
                    textTransform: 'uppercase',
                    fontSize: '1rem',
                    ':hover': {
                        backgroundColor: 'inherit',
                        border: '1px solid black'
                    }
                },
                textPrimary: {
                    color: 'black',
                    backgroundColor: 'inherit',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    ':hover': {
                        backgroundColor: 'inherit',
                        textDecoration: 'none',
                    }
                },
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: `${spacing}px ${spacing * 2}px ${spacing}px ${spacing * 2}px`
                }
            }
        },
        MuiContainer: {
            defaultProps: {
                maxWidth: false
            }
        },
        MuiDialogTitle: {
            defaultProps: {
                component: (props) => <Typography style={{ padding: `${spacing * 2}px ${spacing * 3}px` }} variant="h4">{props.children}</Typography>
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    marginTop: spacing,
                    marginBottom: spacing,
                }
            }
        },
        MuiGrid: {
            defaultProps: {
                spacing: 2
            }
        },
        MuiIconButton: {
            styleOverrides: {
                colorPrimary: {
                    color: 'black'
                },
                colorSecondary: {
                    color: 'red'
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    padding: spacing
                }
            }
        },
        MuiListItemSecondaryAction: {
            styleOverrides: {
                root: {
                    paddingRight: spacing
                }
            }
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    paddingLeft: 0
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: `${spacing}px ${spacing * 2}px ${spacing}px ${spacing * 2}px`
                },
                head: {
                    fontWeight: 600
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.grey[50],
                    fontWeight: 600
                }
            }
        },
        MuiTablePagination: {
            defaultProps: {
                labelDisplayedRows(props) {
                    let pageSize = props.to - props.from + 1
                    return (<span>Page {props.page + 1} of {Math.ceil(props.count / pageSize)}</span>)
                }
            },
        },
    }
});


export default theme;