import { Loading, Page } from "components"
import { Formik } from 'formik';
import { useContext } from "react";
import { userContext, userSchema } from "./UserDetailsContext";
import { UserForm } from './components';

interface IProps {
    isNew: boolean
}

let UserDetails = ({ isNew }: IProps) => {
    let service = useContext(userContext);

    return (
        <Page title={isNew ? "New user" : "Edit user"} maxWidth="md">
            {service.isLoading === true && <Loading />}
            {service.isLoading === false &&
                <Formik
                    enableReinitialize={true}
                    initialValues={service.user}
                    validationSchema={userSchema}
                    onSubmit={async (values, action) => {
                        await service.saveUser(values);
                        action.setSubmitting(false);
                    }}
                >
                    <UserForm isNew={isNew} />
                </Formik>
            }
        </Page>
    )
}

export default UserDetails;