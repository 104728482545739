import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';


interface IMarketingToolContext {
    isLoading: boolean
    marketingTool: IMarketingTool
    currentTab: string
    showMore: boolean
    selectedProducts: string[]

    changeTab: (newTab: string) => void
    handleChangeShowMore: (value: boolean) => void
    handleChangeSelectedProducts: (value: string) => void
}

interface IMarketingTool {
    brand: string
    date: moment.Moment
    extension: string
    lastUpdatedOn: moment.Moment
    mainMediaId: string
    marketingToolId: string
    mediaTypes: IMediaType[]
    products: IProduct[]
    title: string
    year: number
}

interface IMediaType {
    type: string
    medias: IMedia[]
}

interface IProduct {
    brand: string
    id: string
    mainMediaId: string
    mediaTypes: IMediaType[]
    sku: string
    shortDescription: string
    videos: any
}

interface IMedia {
    byte: number
    createdOn: moment.Moment
    extension: string
    fileName: string
    fileSize: string
    iconType: string
    isVideo: boolean
    lastUpdatedOn: moment.Moment
    mediaId: string
    title: string
    vimeoId: string
}

export let marketingToolContext = React.createContext({} as IMarketingToolContext);

let { Provider } = marketingToolContext;

let MarketingToolProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let { id } = useParams<{ id: string }>();

    let [currentTab, setCurrentTab] = useState<string>('');
    let [isLoading, setLoading] = useState(true);

    let [marketingTool, setMarketingTool] = useState({} as IMarketingTool);
    let [showMore, setShowMore] = useState(false);
    let [selectedProducts, setSelectedProducts] = useState<string[]>([]);


    useEffect(() => {
        let getMarketingTool = async () => {
            try {
                setLoading(true);

                let res = await axios.get('/api/MarketingTool/GetMarketingTool', { params: { id: id } });
                let { item } = res.data;

                setMarketingTool(item);

            } catch (err: any) { }
            finally {
                setLoading(false);
            }
        }

        getMarketingTool();
    }, [])

    let changeTab = (newTab: string) => {
        setCurrentTab(newTab)
    }

    let handleChangeShowMore = (value: boolean) => {
        setShowMore(value)
    }

    let handleChangeSelectedProducts = (value: string) => {
        let idx = selectedProducts.findIndex(x => x === value);
        if (idx === -1)
            selectedProducts.push(value);
        else
            selectedProducts = selectedProducts.filter(x => x !== value);

        setSelectedProducts([...selectedProducts]);
    }


    return (
        <Provider value={{
            isLoading,
            marketingTool,
            currentTab,
            showMore,
            selectedProducts,

            changeTab,
            handleChangeShowMore,
            handleChangeSelectedProducts
        }}>
            {children}
        </Provider>
    );
}

export default MarketingToolProvider;