import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

interface IContext {
    isLoading: boolean
    user: User

    saveUser: (values: User) => Promise<void>
}

export let userSchema = yup.object({
    id: yup.string().default(''),
    email: yup.string().default('').required('Email is mandatory field'),
    firstName: yup.string().default('').required('Firstname is mandatory field'),
    lastName: yup.string().default('').required('Lastname is mandatory field'),
    companyName: yup.string().default(''),
    extId: yup.string().default(''),
    canUpdateSalesforceAccount: yup.boolean().default(true),
    salesforceIntegration: yup.boolean().default(false),
    brands: yup.array(yup.string()).default([]).min(1, 'At least one brand must be selected'),
    markets: yup.array(yup.string()).default([]).min(1, 'At least one market must be selected'),
    contactTypes: yup.array(yup.string()).default([]).min(1, 'At least one contact type must be selected'),
    countryCode: yup.string().default('').required('Country is mandatory field'),
});

export type User = yup.InferType<typeof userSchema>;

export let userContext = React.createContext({} as IContext);

let { Provider } = userContext;

let UserDetailsProvider: React.FC<{ isNew: boolean, children: React.ReactNode }> = ({ isNew, children }) => {
    let { id } = useParams<{ id: string }>();
    let navigate = useNavigate();
    let { enqueueSnackbar } = useSnackbar();

    let [isLoading, setLoading] = useState(isNew === false);
    let [user, setUser] = useState<User>(userSchema.getDefault());


    useEffect(() => {
        if (id && id !== '')
            getUser();
    }, [id, isNew])


    let getUser = async () => {
        try {
            let res = await axios.get('/api/UserVisibility/GetUser', { params: { id } });

            let { item } = res.data;

            setUser({
                ...item,
                id: isNew === false ? item.id : userSchema.getDefault().id,
                firstName: isNew === false ? (item.firstName || userSchema.getDefault().firstName) : '',
                lastName: isNew === false ? (item.lastName || userSchema.getDefault().lastName) : '',
                email: isNew === false ? item.email : userSchema.getDefault().email,
                companyName: item.companyName || userSchema.getDefault().companyName,
                extId: item.extId || userSchema.getDefault().extId
            });
        } catch (err) { }
        finally {
            setLoading(false);
        }
    }

    let saveUser = async (values: User) => {
        try {
            let response = await axios.post('/api/UserVisibility/SaveUser', values);

            if (response.data.isNewCognitoUser)
                enqueueSnackbar(response.data.isNewCognitoUser === true
                    ? "The user has been created and will receive a password for the first login"
                    : "The user is already in your systems and can log in with the current password"
                    , { variant: "info" });

            navigate('/admin/users');
        } catch (err) {
        }
    }


    return (
        <Provider
            value={{
                isLoading,
                user,

                saveUser
            }}
        >
            {children}
        </Provider>
    )
}

export default UserDetailsProvider;