import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Layout, Loading } from 'components';
import { authContext } from 'helpers/services/Authentication';
import { Outlet } from 'react-router-dom';

let PrivateRoute = () => {
    let location = useLocation();
    let { isLoading, isAuthenticated } = useContext(authContext);

    return (
        <Layout>
            {isLoading === true && <Loading />}
            {isLoading === false &&
                <>
                    {isAuthenticated === false && <Navigate to={`/account/login?returnUrl=${location.pathname}`} />}
                    {isAuthenticated === true && <Outlet />}
                </>
            }
        </Layout>
    )
}

export default PrivateRoute;