import { useContext, useState } from 'react';
import { Typography, Button, Grid, CircularProgress } from '@mui/material';
import { LockOpenOutlined as UnLockIcon } from '@mui/icons-material';
import { authContext } from 'helpers/services';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { TextField } from 'formik-mui';
import { useLocation } from 'react-router-dom';


let ResetPassword = () => {
    let location = useLocation();

    let authService = useContext(authContext);

    let [isLoading, setLoading] = useState(false);

    let resetPasswordSchema = yup.object({
        code: yup.string().default('').required("Wrong code"),
        email: yup.string().default('').required("Email is mandatory field"),
        password: yup.string().default('').required("New password is mandatory field"),
        passwordConfirm: yup.string()
            .oneOf([yup.ref('password'), null], "Passwords does not match")
            .required("New password is mandatory field")
            .default('')
    })


    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h4">Reset password</Typography>
                <Typography>Please set the code that we have sent to your email and new password</Typography>
            </Grid>
            <Grid item xs>
                <Formik
                    initialValues={{
                        ...resetPasswordSchema.getDefault(),
                        email: location.state?.email || ''
                    }}
                    validationSchema={resetPasswordSchema}
                    onSubmit={async (values, action) => {
                        setLoading(true);
                        await authService.forgotPasswordSubmit(values.email, values.code, values.password);
                    }}
                >
                    <Form>
                        <Grid container alignItems="center">
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    type="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    name="code"
                                    label="Code"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    name="password"
                                    label="Password"
                                    variant="outlined"
                                    fullWidth
                                    type="password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    name="passwordConfirm"
                                    label="Confirm password"
                                    variant="outlined"
                                    fullWidth
                                    type="password"
                                />
                            </Grid>
                            <Grid item xs>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    disabled={isLoading}
                                    startIcon={isLoading ? <CircularProgress size={20} /> : <UnLockIcon />}
                                >
                                    Reset password
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </Grid>
        </Grid>
    )
}

export default ResetPassword;