import { LocalizationKey } from "helpers/Enums";

let itIT = {
    /*  ERROR 403  */
    [LocalizationKey.Error403]: "L'tente non dispone dei permessi necessari per accedere alla pagina",
    [LocalizationKey.Error403Note]: "Prova a disconnetterti e ad accedere nuovamente: se non cambia nulla chiedi al supporto tecnico.",

    /*  ERROR 404  */
    [LocalizationKey.Error404]: "404: La pagina che stai cercando non è qui",
    [LocalizationKey.Error404Note]: "O hai provato qualche strada losca o sei venuto qui per sbaglio. Qualunque sia il motivo, prova a utilizzare i pulsanti di navigazione",

    /*  GENERAL  */
    [LocalizationKey.Hi]: "Ciao",
    [LocalizationKey.Logout]: "Esci",
    [LocalizationKey.PrivacyLink]: "https://www.videndummediasolutions.com/it/privacy-policy/",
    [LocalizationKey.DownloadError]: "Non è stato possibile completare la richiesta. Si prega di ricaricare la pagina o riprovare.",
    [LocalizationKey.DownloadSuccess]: "Richiesta processata correttamente",
    [LocalizationKey.DownloadInProgress]: "Download in corso",
    [LocalizationKey.RequestSent]: "Richiesta inviata",
    [LocalizationKey.OurBrands]: "I nostri brand",
    [LocalizationKey.NoResultsFound]: "Nessun risultato trovato",
    [LocalizationKey.Markets]: "Markets",
    [LocalizationKey.Brand]: "Brand",
    [LocalizationKey.Brands]: "Brands",
    [LocalizationKey.ViewMore]: "Visualizza di più",
    [LocalizationKey.Products]: "Prodotti",
    [LocalizationKey.DownloadAllImages]: "Scarica tutte le immagini",
    [LocalizationKey.DownloadAllImagesNotes]: "Invieremo un file ZIP al tuo indirizzo email {emailAddress}",
    [LocalizationKey.DownloadSelectedImages]: "Scarica le immagini selezionate",
    [LocalizationKey.Download]: "Download",
    [LocalizationKey.ProductDetail]: "Dettaglio prodotto",
    [LocalizationKey.PhoneNumber]: "+39 0424 555855",
    [LocalizationKey.MarketingTools]: "Bulletin",
    [LocalizationKey.Search]: "Cerca",
    [LocalizationKey.Any]: "Qualsiasi",
    [LocalizationKey.Previous]: "Precedente",
    [LocalizationKey.Next]: "Successivo",
    [LocalizationKey.Close]: "Chiudi",
    [LocalizationKey.Confirm]: "Conferma",
    [LocalizationKey.Page]: "Pagina",
    [LocalizationKey.Of]: "di",

    /*  HOMEPAGE  */
    [LocalizationKey.HomepageWelcomeTo]: "BENVENUTO IN VIDENDUM MEDIA DISTRIBUTION",
    [LocalizationKey.HomepageWelcomeAnonymus]: "Benvenuto in {videndumBold}, uno spazio dedicato alla nostra rete ufficiale di rivenditori.\nEsegui il login per accedere a tutti gli strumenti di {productInfoBold}, e per scoprire tutte le ultime novità dal mondo Videndum Media Solutions.",
    [LocalizationKey.HomepageWelcomeAuthenticated]: "Benvenuto in {videndumBold}, uno spazio dedicato alla nostra rete ufficiale di rivenditori.",
    [LocalizationKey.HomepageAboutUs]: "ABOUT US",
    [LocalizationKey.HomepageAboutUsLine1]: "Videndum Media Solutions progetta, produce e distribuisce accessori fotografici e video di alta qualità come treppiedi, borse, filtri e luci per fotografi professionisti e amatoriali e per videografi.",
    [LocalizationKey.HomepageAboutUsLine2]: "I prodotti Videndum Media Solutions sono commercializzati attraverso le società di distribuzione, Videndum Media Distribution, in Cina, Francia, Germania, Hong Kong, Italia, Benelux, Giappone, Regno Unito e Stati Uniti, cosÃ¬ come attraverso distributori indipendenti in oltre 65 paesi in tutto il mondo. La grandezza della rete distributiva internazionale e il forte lavoro di squadra tra l'azienda ei suoi distributori sono fattori chiave nel successo eccezionale della linea di prodotti.",
    [LocalizationKey.Videndum]: "Videndum Media Distributors Area",
    [LocalizationKey.ProductInformation]: "marketing di prodotto",
    [LocalizationKey.HomepageWelcomeMarketingTools]: "marketing tools",

    /*  MARKETING TOOLS  */
    [LocalizationKey.HomepageMarketingTools]: "Marketing Tools",
    [LocalizationKey.Filters]: "Filtri",
    [LocalizationKey.SearchByTitle]: "Cerca per titolo o sku...",
    [LocalizationKey.Category]: "Categoria",
    [LocalizationKey.Year]: "Anno",
    [LocalizationKey.MarketingToolsFound]: "Marketing tools trovati",
    [LocalizationKey.ProductsInMarketingTool]: "Prodotti in questo Marketing Tool",
    [LocalizationKey.ShowAll]: "Mostra tutto",
    [LocalizationKey.ShowLess]: "Mostra meno",
    [LocalizationKey.BackToSearch]: "Torna alla ricerca",
    [LocalizationKey.OpenInVimeo]: "Apri in Vimeo",

    /*  PRODUCT DATA  */
    [LocalizationKey.ProductData]: "Dati prodotto",
    [LocalizationKey.ProductDataNotes]: "Questa sezione è stata progettata per fornire i dati tecnici e le specifiche per tutti i prodotti Videndum Media Solutions.\nSe si desidera aprire un negozio on-line, generare procedure automatiche o listini prezzi per la gestione delle attività di e-commerce, troverete tutte le risorse necessarie qui. Queste informazioni vengono fornite nei file di Excel in due formati diversi (file JSON e XML), per un più facile uso, più flessibile.",
    [LocalizationKey.ProductDataJsonInfo]: "JSON è l'acronimo di JavaScript Object Notation, è un formato adatto all'interscambio di dati fra applicazioni client-server \n La semplicità di JSON ne ha decretato un rapido utilizzo specialmente nella programmazione WEB.",
    [LocalizationKey.ProductDataXmlInfo]: "È un linguaggio di markup, ed è utilizzato per immagazinare dei dati e renderli leggibili in modo universale a diversi linguaggi di programmazione.  Viene utilizzato soprattutto in ambito web, ma viene anche usato per esportare i dati da database diversi e uniformarli ad un unico standard",
    [LocalizationKey.ProductImages]: "Immagini prodotto (file zip)",

    /*  VISUAL PRICE LIST  */
    [LocalizationKey.VisualPriceList]: "Catalistino",
    [LocalizationKey.ProductCode]: "Codice prodotto",
    [LocalizationKey.ProductDescription]: "Descrizione prodotto",
    [LocalizationKey.ViewBy]: "Visualizza per",
    [LocalizationKey.Found]: "Trovati",
    [LocalizationKey.Product]: "Prodotto",
    [LocalizationKey.MasterCarton]: "Master carton",
    [LocalizationKey.SuggestedPrice]: "Prezzo suggerito",
    [LocalizationKey.Loading]: "Caricamento...",
    [LocalizationKey.TechnicalSpecifications]: "Specifiche Tecniche",
    [LocalizationKey.Specification]: "Specifica",
    [LocalizationKey.Value]: "Valore",
    [LocalizationKey.CountryOfOrigin]: "Paese di Origine",
}

export default itIT;