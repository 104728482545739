let Cookiebot = () => {

    const script = document.createElement('script');

    script.setAttribute('id', 'Cookiebot');
    script.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
    script.setAttribute('data-cbid', process.env.REACT_APP_COOKIEBOT_GROUPID as string);
    script.setAttribute('data-blockingmode', 'auto');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('data-framework', 'TCFv2.2');

    const head = document.querySelector('html > head');

    if (head)
        head.insertBefore(script, head.firstChild);

    return (<></>)
}

export default Cookiebot;