import { useLocation } from 'react-router-dom';
import UserDetails from './UserDetails';
import UserDetailsProvider from './UserDetailsContext';

let Index = () => {
    let location = useLocation();

    let isNew = location.pathname.includes('/addnew');

    return (
        <UserDetailsProvider isNew={isNew}>
            <UserDetails isNew={isNew} />
        </UserDetailsProvider>
    )
}

export default Index;