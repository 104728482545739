import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { LocalizationKey } from "./Enums";

interface IPaginationProps {
    numberOfItems: number
    pageNr: number
    onPageChange: (pageNr: number) => void
}

let Pagination = ({ numberOfItems, pageNr, onPageChange }: IPaginationProps) => {
    const itemsPerPage = 100;
    let [currentPage, setCurrentPage] = useState<number>(pageNr);
    let [pages, setPages] = useState<number[]>([currentPage]);
    let { formatMessage } = useIntl();

    const numberOfPages = Math.ceil(numberOfItems / itemsPerPage);

    useEffect(() => {
        updatePages();
    }, []);

    let updatePages = () => {
        var currentPages = [currentPage];

        for (var i = currentPage; i <= currentPage + 5; i++) {
            if (i == currentPage)
                continue;

            if (i > currentPage
                && currentPage + (i - currentPage) <= numberOfPages)
                currentPages.push(i);

            if (currentPage - (i - currentPage) >= 1
                && currentPage - (i - currentPage) <= numberOfPages)
                currentPages.unshift(currentPage - (i - currentPage));

            if (currentPages.length == 5)
                break;
        }

        setPages(currentPages);
    }

    let gotoPrevious = () => {
        if (currentPage > 1)
            setCurrentPage(currentPage - 1);

        updatePages();
        onPageChange(currentPage > 1 ? currentPage - 1 : currentPage);
    };

    let gotoNext = () => {
        if (currentPage < numberOfPages)
            setCurrentPage(currentPage + 1);

        updatePages();
        onPageChange(currentPage < numberOfPages ? currentPage + 1 : currentPage);
    };

    let gotoPage = (pageNr: number) => {
        setCurrentPage(pageNr);

        updatePages();
        onPageChange(pageNr);
    }

    let hasNext = () => {
        return currentPage < numberOfPages;
    }

    let hasPrevious = () => {
        return currentPage > 1;
    }

    return (
        <nav aria-label="pagination">
            <ul className="pagination pagination-sm">
                <li className={hasPrevious() ? "page-item" : "page-item disabled"}>
                    <a className="page-link" onClick={(e: any) => gotoPrevious()}>
                        {formatMessage({ id: LocalizationKey.Previous })}
                    </a>
                </li>
                {pages.map(page =>
                    <li key={page} className={currentPage === page ? "page-item active" : "page-item"}>
                        <a className="page-link" onClick={(e: any) => gotoPage(page)}>{page}</a>
                    </li>
                )}
                <li className={hasNext() ? "page-item" : "page-item disabled"}>
                    <a className="page-link" onClick={(e: any) => gotoNext()}>
                        {formatMessage({ id: LocalizationKey.Next })}
                    </a>
                </li>
            </ul>
        </nav>
    );

}

export default Pagination;
