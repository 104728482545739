import { useContext } from "react";
import { marketingToolContext } from "../MarketingToolContext";
import { applicationContext } from "helpers/services";
import { useIntl } from "react-intl";
import { LocalizationKey, UserType } from "helpers/Enums";
import { DownloadAll } from "components";

let Products = () => {
    let appService = useContext(applicationContext);
    let service = useContext(marketingToolContext);
    let { formatMessage } = useIntl();

    return (
        <div className={(service.marketingTool.products.length > 9 && service.showMore === false) ? 'row col-listproduct__long' : "row"}>
            {service.marketingTool.products.map(product =>
                <div className="productbundle" key={product.id}>
                    <div className="productbundle__container">
                        <div className="row productbundle__content">
                            {appService.currentUser.salesforceId !== '' &&
                                <div className="productbundle__checkbox">
                                    <input
                                        type="checkbox"
                                        name="brand"
                                        value={product.id}
                                        checked={service.selectedProducts.findIndex(x => x === product.id) !== -1}
                                        onChange={(e: any) => service.handleChangeSelectedProducts(e.target.value)}
                                    />
                                </div>
                            }
                            <div className="productbundle__links">
                                <a
                                    target="_blank"
                                    href={`/home/productdetail?sku=${product.sku}`}
                                >
                                    <i className="fa fa-search" /> {formatMessage({ id: LocalizationKey.ProductDetail })}
                                </a>
                            </div>
                            {appService.currentUser.userType === UserType.External
                                && product.mediaTypes?.length > 0
                                && appService.currentUser.salesforceId !== '' &&
                                <div className="productbundle__button">
                                    <DownloadAll
                                        component="a"
                                        linkedTo="Product"
                                        id={product.id}
                                    />
                                </div>
                            }
                            <div className="col col-auto productbundle__mainimg">
                                <img src={`${process.env.REACT_APP_MEDIA_CDN}/byProduct?sku=${product.sku}&width=150&heigth=90`} />
                            </div>
                            <div className="col">
                                <strong>{product.sku}</strong>
                                <h5>{product.shortDescription}</h5>
                                <span>{appService.availableBrands.getValueByKey(product.brand)}</span>
                            </div>
                        </div>
                        {product.mediaTypes.map(mediaType =>
                            <div className="productbundle__gallery" key={mediaType.type}>
                                {mediaType.medias.map(media =>
                                    <a
                                        key={media.mediaId}
                                        rel={`prettyPhoto[${product.sku}]`}
                                        title={media.title}
                                        href={`${process.env.REACT_APP_MEDIA_CDN}/${media.mediaId}?width=1000`}
                                    >
                                        <img
                                            style={{ maxWidth: 64, maxHeight: 32 }}
                                            src={`${process.env.REACT_APP_MEDIA_CDN}/${media.mediaId}?width=64&heigth=32`}
                                        />
                                    </a>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Products;