import { Page } from "components";
import { LocalizationKey } from "helpers/Enums";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { downloadContext } from "./DownloadRequestProvider";
import { Alert, CircularProgress } from "@mui/material";

let DownloadRequest = () => {
    let { formatMessage } = useIntl();

    let { alert } = useContext(downloadContext);

    return (
        <Page title={formatMessage({ id: LocalizationKey.VisualPriceList })}>
            <Alert
                style={{ padding: 16, margin: 8 }}
                severity={alert.severity}
                action={alert.isProcessing && <CircularProgress size={24} />}
            >
                {formatMessage({ id: alert.messageKey })}
            </Alert>
        </Page>
    )
}

export default DownloadRequest;