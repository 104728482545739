import { Loading, Page } from "components";
import { useContext } from "react";
import { Filters, Results, Toolbar } from "./components";
import { usersContext } from "./UsersContext";


let Users = () => {
    let service = useContext(usersContext);

    return (
        <Page title="Users">
            <div className="container-fluid special-p" style={{ marginTop: 18, marginBottom: 18 }}>
                {service.isLoading && <Loading />}
                {service.isLoading === false &&
                    <>
                        <Filters />
                        <Toolbar />
                        <Results />
                    </>
                }
            </div>
        </Page>
    )
}

export default Users;