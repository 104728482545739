import { useContext } from "react";
import ProductCard from "./ProductCard";
import Pagination from "helpers/Pagination";
import { visualPriceListContext } from "../VisualPriceListContext";
import { LocalizationKey } from "helpers/Enums";
import { useIntl } from "react-intl";


let GridView = () => {
    let service = useContext(visualPriceListContext);
    let { formatMessage } = useIntl();

    return (
        <div className="grid active">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-md-6">
                            <h6>
                                <span className="badge badge-primary">
                                    {formatMessage({ id: LocalizationKey.Found })} <span>{service.recordCount}</span> {formatMessage({ id: LocalizationKey.Products }).toLowerCase()}
                                </span>
                            </h6>
                        </div>
                        <div className="col-auto ml-auto">
                            <Pagination
                                numberOfItems={service.recordCount}
                                pageNr={service.search.pageNr}
                                onPageChange={(pageNr: number) => service.handleChangePage({} as any, pageNr)}
                            />
                        </div>
                    </div>

                    <div className="">
                        <div className="row no-gutters categoryProduct xsResponse clearfix">
                            {service.products.map(product =>
                                <div key={product.sku} className="item col-sm-4 col-md-3 col-xxl-2 col-xs-6">
                                    <ProductCard product={product} />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <h6>
                                <span className="badge badge-primary">
                                    {formatMessage({ id: LocalizationKey.Found })} <span>{service.recordCount}</span> {formatMessage({ id: LocalizationKey.Products }).toLowerCase()}
                                </span>
                            </h6>
                        </div>
                        <div className="col-auto ml-auto">
                            <Pagination
                                numberOfItems={service.recordCount}
                                pageNr={service.search.pageNr}
                                onPageChange={(pageNr: number) => service.handleChangePage({} as any, pageNr)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GridView;