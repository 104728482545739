import ProductDetails from './ProductDetails';
import ProductDetailsProvider from './ProductDetailsContext';


let Index = () => {
    return (
        <ProductDetailsProvider>
            <ProductDetails />
        </ProductDetailsProvider>
    )
}

export default Index;