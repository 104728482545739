import { LocalizationKey } from "helpers/Enums";
import { useIntl } from "react-intl"

let Loading = () => {
    let { formatMessage } = useIntl();

    return (
        <div className="loading-data">
            <h3>{formatMessage({ id: LocalizationKey.Loading })}</h3>
        </div>
    )
}

export default Loading;