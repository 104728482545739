import Users from './Users';
import UsersProvider from './UsersContext';

let Index = () => {
    return (
        <UsersProvider>
            <Users />
        </UsersProvider>
    )
}

export default Index;