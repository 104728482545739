import { Page } from "components"
import { useContext } from "react";
import { Header, Results } from "./components";
import { applicationContext } from "helpers/services";


const ProductData = () => {
    const { availableBrands } = useContext(applicationContext);

    const brands = ["MNF", "GTZ", "AVE", "NG", "LWP", "JBY", "BIN", "SAV", "RYC"]

    return (
        <Page title="Product data">
            <div className="main-container headerOffset globalPaddingBottom">
                <div className="container-fluid special-p productdata">
                    <Header />
                    <div className="row listproductdata">
                        {availableBrands
                            .filter(x => brands.indexOf(x.key) !== -1)
                            .map(brand =>
                                <Results
                                    key={brand.key}
                                    brandName={brand.value}
                                    files={['ProductData', 'Specification', 'ProductImages']}
                                />
                            )}
                    </div>
                </div>
            </div>
        </Page>
    );

}

export default ProductData;