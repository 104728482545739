import { useContext, useEffect, useState } from 'react';
import { Typography, Button, Grid, CircularProgress } from '@mui/material';
import { LockOpenOutlined as UnLockIcon } from '@mui/icons-material';
import { authContext } from 'helpers/services';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { TextField } from 'formik-mui';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


let ForgotPassword = () => {
    let { isLoginOpen, closeLogin } = useContext(authContext);

    const navigate = useNavigate();
    let [isLoading, setLoading] = useState(false);

    let forgotPasswordSchema = yup.object({
        email: yup.string().default('').required("Email is mandatory"),
    });



    useEffect(() => {
        if (isLoginOpen === true)
            closeLogin();
    }, []);


    let resetPassword = async (email: string) => {
        try {
            let res = await axios.get(`/api/User/ResetPassword`, { params: { email: email } });
            if (res.data === true)
                navigate("/account/resetpassword", { state: { email: email } });
            else
                navigate("/account/login");

            return true;
        }
        catch {
            return false;
        }

    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h4">Forgot your password?</Typography>
                <Typography>Enter your email</Typography>
            </Grid>
            <Grid item xs>
                <Formik
                    initialValues={forgotPasswordSchema.getDefault()}
                    validationSchema={forgotPasswordSchema}
                    onSubmit={async (values, action) => {
                        setLoading(true);
                        let res = await resetPassword(values.email);
                        if (res === false)
                            setLoading(false);
                    }}
                >
                    <Form>
                        <Grid container alignItems="center">
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    type="email"
                                />
                            </Grid>
                            <Grid item xs>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    disabled={isLoading}
                                    startIcon={isLoading ? <CircularProgress size={20} /> : <UnLockIcon />}
                                >
                                    Send code
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </Grid>
        </Grid>
    )
}

export default ForgotPassword;