import { useContext } from "react";
import { useIntl } from "react-intl";
import { LocalizationKey } from "helpers/Enums";
import { applicationContext, authContext } from "helpers/services";

const Topbar = () => {
    const { formatMessage } = useIntl();

    const { isAuthenticated, openLogin } = useContext(authContext);

    const { brands, currentUser } = useContext(applicationContext);



    return (
        <div className="top-bar">
            <div className="d-flex align-items-center">
                <div className="col-auto d-flex align-items-center">
                    <div className="vitec-brands">
                        <div className="vitec-brands__item">
                            <ul className='head-brands'>
                                {brands.map(brand =>
                                    <li className={`logo-${brand.key.toLowerCase()}-lg-k`} key={brand.key}>
                                        <a href={brand.url} target='_blank' />
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-auto ml-auto d-flex align-items-end">
                    <div className="row no-gutters w-100">
                        <div className="col-auto ml-auto pr-4">
                            <a className="d-none" style={{ cursor: 'default' }}>
                                <span>Distribution Area</span>
                            </a>
                        </div>
                        <div className="col-auto pr-4 d-none d-sm-block">
                            <a href={`tel:${formatMessage({ id: LocalizationKey.PhoneNumber })}`}>
                                <span>
                                    <i className="fa fa-phone-square" />
                                </span>
                                <span>
                                    <strong className="m-l-10 text-red"> {formatMessage({ id: LocalizationKey.PhoneNumber })}</strong>
                                </span>
                            </a>
                        </div>

                        <div className="col-auto loginbtn">
                            <div className="col-auto loginbtn">
                                <a onClick={openLogin} style={{ cursor: 'pointer' }}>
                                    <i className="fa fa-user" />&nbsp;&nbsp;{isAuthenticated === false
                                        ? 'Login'
                                        : (currentUser.firstName !== '' ? currentUser.firstName : currentUser.lastName)}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Topbar;