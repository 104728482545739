import { useContext } from "react";
import { productDetailsContext } from "../ProductDetailsContext";
import { useIntl } from "react-intl";
import { LocalizationKey } from "helpers/Enums";
import { NumericFormat } from 'react-number-format';
import Moment from "react-moment";


let RightColumn = () => {
    let service = useContext(productDetailsContext);
    let { formatMessage } = useIntl();

    return (
        <>
            <div className="row main-info">
                <div className="col-xxl-6  order-12 order-xxl-1">
                    <ul className="bullet-points">
                        {service.productDetails.bulletPoint1 !== null &&
                            <li>{service.productDetails.bulletPoint1 === " " ? '-' : service.productDetails.bulletPoint1}</li>
                        }
                        {service.productDetails.bulletPoint2 !== null &&
                            <li>{service.productDetails.bulletPoint2 === " " ? '-' : service.productDetails.bulletPoint2}</li>
                        }
                        {service.productDetails.bulletPoint3 !== null &&
                            <li>{service.productDetails.bulletPoint3 === " " ? '-' : service.productDetails.bulletPoint3}</li>
                        }
                        {service.productDetails.bulletPoint4 !== null &&
                            <li>{service.productDetails.bulletPoint4 === " " ? '-' : service.productDetails.bulletPoint4}</li>
                        }
                        {service.productDetails.bulletPoint5 !== null &&
                            <li>{service.productDetails.bulletPoint5 === " " ? '-' : service.productDetails.bulletPoint5}</li>
                        }
                    </ul>
                </div>
                <div className="col-xxl-6  order-1 order-xxl-12">
                    <div className="detail-price">
                        <div className="row no-gutters">
                            <div className="col-xl-12">
                                <div className="row no-gutters">
                                    <div className="col-xl-6 col-xxl-12 detail-price__code">
                                        <span>EAN</span>
                                        <strong> {service.productDetails.ean}</strong>
                                    </div>
                                    <div className="col-xl-6 col-xxl-12 detail-price__code">
                                        <span>UPC</span>
                                        <strong> {service.productDetails.upc}</strong>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-xl-12 col-xxl-12 detail-price__country">
                                        <span>{formatMessage({ id: LocalizationKey.CountryOfOrigin })}</span>
                                        <strong> {service.productDetails.countryOfOriginCode} </strong>
                                        - <span> {service.productDetails.countryOfOriginDescription}</span>
                                    </div>
                                </div>
                            </div>
                            {(service.productDetails.price !== null && service.productDetails.price > 0) &&
                                <div className="col-xl-12">
                                    <h2 className="suggestedprice">
                                        <small>{formatMessage({ id: LocalizationKey.SuggestedPrice })}</small>
                                        {service.productDetails.price !== 0 &&
                                            <NumericFormat
                                                className="price-number"
                                                displayType="text"
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                decimalScale={2}
                                                fixedDecimalScale
                                                prefix={`${service.productDetails.currency} `}
                                                value={service.productDetails.price}
                                            />
                                        }
                                    </h2>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="description">
                <h4>{formatMessage({ id: LocalizationKey.ProductDescription })}</h4>
                <div className="long-text">
                    <input type="checkbox" />
                    <p className="long-text__text" dangerouslySetInnerHTML={{ __html: service.productDetails.longDescriptions }}>
                    </p>
                </div>
            </div>

            <div className="techspecification">
                <h4>{formatMessage({ id: LocalizationKey.TechnicalSpecifications })}</h4>
                <table className="table table-condensed table-striped">
                    <thead>
                        <tr>
                            <th>{formatMessage({ id: LocalizationKey.Specification })}</th>
                            <th>{formatMessage({ id: LocalizationKey.Value })}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {service.productDetails.specifications.map(spec =>
                            <tr key={spec.label}>
                                <td>{spec.label}</td>
                                <td>
                                    <span>{spec.value}</span>
                                    <span>{spec.unit}</span>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {service.productDetails.marketingTools.length > 0 &&
                <div>
                    <h4>{formatMessage({ id: LocalizationKey.MarketingTools })}</h4>
                    {service.productDetails.marketingTools.map(mkTool =>
                        <div className="details__header" key={mkTool.title}>
                            <div>
                                <div className={`brand-sign c-${mkTool.brand.toLowerCase()}`}>
                                    <div className={`logo logo-${mkTool.brand.toLowerCase()}-lg-w`}>
                                        <span></span>
                                    </div>
                                </div>
                                <div className="details__header__container">
                                    <h4 className="product-code">
                                        <span>
                                            <Moment date={mkTool.date} format='MMM YYYY' />
                                        </span>
                                    </h4>
                                    <h5 className="product-title">{mkTool.title}</h5>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }
            
            {service.productDetails.warranty &&
                <div className="warranty">
                    <h4>{service.productDetails.warranty.title}</h4>
                    <div className="long-text">
                        <input type="checkbox" />
                        <p className="long-text__text" dangerouslySetInnerHTML={{ __html: service.productDetails.warranty.description }}>
                        </p>
                    </div>
                </div>
            }
        </>
    );
}

export default RightColumn;