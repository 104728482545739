import { Container, Typography } from '@mui/material';
import { LocalizationKey } from 'helpers/Enums';
import { useIntl } from 'react-intl';


let Error404 = () => {
  let { formatMessage } = useIntl();

  return (
    <Container maxWidth="lg">
      <Typography align="center" variant="h2" sx={{ mt: 7 }}>
        {formatMessage({ id: LocalizationKey.Error404 })}
      </Typography>
      <Typography align="center" variant="subtitle2" sx={{ mb: 8.2 }}>
        {formatMessage({ id: LocalizationKey.Error404Note }, { homeLink: <a href="/">paffe d'accueil</a> })}
      </Typography>
      <img alt="Under development" src="/images/errors/404.svg" />
    </Container>
  );
};

export default Error404;