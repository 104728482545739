import { useContext } from 'react';
import { Button, Drawer } from '@mui/material';
import { authContext } from 'helpers/services/Authentication';
import { applicationContext } from 'helpers/services';
import { ChangePassword, Login } from 'components';
import { useIntl } from 'react-intl';
import { LocalizationKey } from 'helpers/Enums';
import { useLocation } from 'react-router-dom';


const UserDrawer = () => {
    const { formatMessage } = useIntl();
    let location = useLocation();

    const { currentUser } = useContext(applicationContext);
    const { isAuthenticated, isLoginOpen, closeLogin, signOut } = useContext(authContext);
    let changePassword = location.pathname.includes('/changepassword');

    return (
        <Drawer anchor='right' open={isLoginOpen} onClose={closeLogin} style={{ zIndex: 6 }}>
            <div style={{ top: 0, right: 0, width: '100%', maxWidth: 480, padding: '110px 30px 30px 30px' }}>
                {isAuthenticated === true &&
                    <>
                        <p className="lead">
                            {formatMessage({ id: LocalizationKey.Hi })}&nbsp;{currentUser.firstName}&nbsp;{currentUser.lastName}<br />
                            <small>{currentUser.companyName}</small>
                        </p>

                        <div style={{ float: 'right' }}>
                            <i className="fa fa-sign-out" />
                            <Button color="primary" variant="text" style={{ padding: 0 }} onClick={async () => await signOut()}>
                                {formatMessage({ id: LocalizationKey.Logout })}
                            </Button>
                        </div>
                    </>
                }
                {isAuthenticated === false && changePassword === false &&
                    <Login />
                }
                {isAuthenticated === false && changePassword === true &&
                    <ChangePassword />
                }
            </div>
        </Drawer>
    )
}

export default UserDrawer;