import React from "react";
import { useContext } from "react";
import Moment from "react-moment";
import { marketingToolContext } from "../MarketingToolContext";
import { applicationContext } from "helpers/services";
import { useIntl } from "react-intl";
import { LocalizationKey } from "helpers/Enums";

let MediaTypes = () => {
    let service = useContext(marketingToolContext);
    let appService = useContext(applicationContext);

    let { formatMessage } = useIntl();

    return (
        <>
            {service.marketingTool.mediaTypes
                .filter(x => service.currentTab ? x.type === service.currentTab : true)
                .map(mediaType =>
                    <div key={mediaType.type} className={mediaType.type === 'EmotionalImage' ? "col-media__gallery" : "col-media__multiple-files"}>
                        <h4 className="text-left">
                            <span> {appService.mediaTypes.getValueByKey(mediaType.type) ?? mediaType.type}</span>
                        </h4>
                        <div className="typegroup">
                            {mediaType.medias.map(media =>
                                <React.Fragment key={media.mediaId}>
                                    {media.isVideo === false &&
                                        <div className="typegroup__element">
                                            <div className="typegroup__element__file">
                                                {media.extension.toLowerCase() !== 'jpeg' && media.extension.toLowerCase() !== 'jpg'
                                                    && media.extension.toLowerCase() !== 'png' && media.extension.toLowerCase() !== 'gif'
                                                    &&
                                                    <div className={`file-type file-${media.extension.toLowerCase()}`}>
                                                        <span className="badge badge-dark">{media.extension.toLowerCase()}</span>
                                                    </div>
                                                }
                                                {((media.extension.toLowerCase() == 'jpeg' || media.extension.toLowerCase() == 'jpg'
                                                    || media.extension.toLowerCase() == 'png' || media.extension.toLowerCase() == 'gif')
                                                    && media.isVideo === false) &&
                                                    <a
                                                        style={{ cursor: 'pointer' }}
                                                        rel="prettyPhoto[emotionalimage]"
                                                        title={media.title}
                                                        href={`${process.env.REACT_APP_MEDIA_CDN}/${media.mediaId}?width=1000`}
                                                    >
                                                        <img src={`${process.env.REACT_APP_MEDIA_CDN}/${media.mediaId}?width=200&heigth=200`} />
                                                    </a>
                                                }
                                            </div>
                                            <div className="typegroup__element__text">
                                                <strong>{media.title}</strong>
                                                <small>
                                                    <Moment date={media.createdOn} format="MMM YYYY" />
                                                </small>
                                            </div>
                                            <div className="typegroup__element__links">

                                                <a
                                                    className="download"
                                                    target="_blank"
                                                    href={`${process.env.REACT_APP_MEDIA_CDN}/download/${media.mediaId}/${appService.currentUser.localeCode}`}
                                                >
                                                    <i className="fa fa-download" /> {formatMessage({ id: LocalizationKey.Download })}
                                                </a>
                                                {/* 
                                                    &nbsp;
                                                    <a className="add2cart">
                                                    <span><i className="fa fa-cart-plus" /> Add to cart </span>
                                                    </a> 
                                                */}
                                            </div>
                                        </div>
                                    }
                                    {media.isVideo === true &&
                                        <div className="typegroup_video_element">
                                            <div className="typegroup__element__file">
                                                <iframe
                                                    src={`https://player.vimeo.com/video/${media.vimeoId}`}
                                                    style={{ border: 'none', width: '100%' }}
                                                />
                                            </div>
                                            <div className="typegroup__element__text">
                                                <p>{media.title}</p>
                                                <p>
                                                    <a href={`https://vimeo.com/${media.vimeoId}`}>{formatMessage({ id: LocalizationKey.OpenInVimeo })}</a>
                                                </p>
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default MediaTypes;