/* eslint-disable no-extend-native */
declare global {
    interface Array<T> {
        getValueByKey(key: any, keyField?: string, valueField?: string): any;
        orderBy(fieldName?: string, sortDescending?: boolean): Array<T>;
    }
}

Array.prototype.getValueByKey = function (key: any, keyField: string = 'key', valueField: string = 'value') {
    let obj = this.find((x: any) => x[keyField] === key);

    if (!obj)
        return undefined;

    return obj[valueField];
};

export { };