import { LocalizationKey } from "helpers/Enums";
import { IProduct } from "../VisualPriceListContext";
import { useIntl } from "react-intl";
import { NumericFormat } from "react-number-format";

interface IproductCard {
    product: IProduct
}

let ProductCard = ({ product }: IproductCard) => {
    let { formatMessage } = useIntl();

    return (
        <div className="product">
            <div className="description">
                <div className="grid-description">
                    <div className="container-img">
                        <div className={`brand-sign c-${product.brandCode.toLowerCase()}`}>
                            <div className={`logo logo-${product.brandCode.toLowerCase()}-lg-c`}>
                                <span></span>
                                <img src={`/Content/img/brand/200x200/${product.brandCode.toUpperCase()}.png`}
                                    width={200}
                                />
                            </div>
                        </div>
                        <div className="visual-price-list-img">
                            <img src={`${process.env.REACT_APP_MEDIA_CDN}/byproduct?sku=${product.sku}&heigth=200&width=200`} />
                        </div>
                    </div>
                    <span className="product-code">{product.sku}</span>&nbsp;
                    <h3 className="incaps">{product.description}</h3>
                    {product.suggestedPrice !== 0 &&
                        <p className="product-type">
                            <NumericFormat
                                className="price-number"
                                displayType="text"
                                decimalSeparator=","
                                thousandSeparator="."
                                decimalScale={2}
                                fixedDecimalScale
                                prefix={`${product.currency} `}
                                value={product.suggestedPrice}
                            />
                            <span className="price-desc">{formatMessage({ id: LocalizationKey.SuggestedPrice })}</span>
                        </p>
                    }
                </div>
            </div>
            <div className="loadMoreBlog text-center">
                <a
                    className="btn btn-block"
                    target="_blank"
                    href={`/home/productdetail?sku=${product.sku}`}
                >
                    <p>{formatMessage({ id: LocalizationKey.ViewMore })}</p>
                </a>
            </div>
        </div>
    );
}
export default ProductCard;