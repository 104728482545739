import { Page } from "components";
import { useEffect, useRef } from "react";

let Cookies = () => {
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.id = "CookieDeclaration";
    script.src = `https://consent.cookiebot.com/${process.env.REACT_APP_COOKIEBOT_GROUPID}/cd.js`;
    script.async = true;

    let divref = useRef<any>();

    useEffect(() => {
        if (divref.current)
            divref.current.appendChild(script);
    }, [divref.current])

    return (
        <Page title="Cookies">
            <div ref={divref}></div>
        </Page>
    )
}

export default Cookies;