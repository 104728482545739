import { Tooltip, Zoom, IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

interface IProps {
    onClick: () => void
}

let DeleteIconButton = ({ onClick }: IProps) => {
    return (
        <Tooltip title="Delete" TransitionComponent={Zoom}>
            <IconButton
                size="small"
                color="secondary"
                onClick={ev => onClick()}
            >
                <DeleteIcon />
            </IconButton>
        </Tooltip>
    )
}

export default DeleteIconButton;