import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { IVMSUser } from 'helpers/interfaces';
import KeyValuePair from 'helpers/KeyValuePair';
import { authContext } from './Authentication';
import { Theme, useMediaQuery } from '@mui/material';
import moment from 'moment';
import ReactGA from "react-ga4";


interface IApplicationContext {
    isMobile: boolean
    isLoading: boolean
    currentUser: IVMSUser
    countries: KeyValuePair<string, string>[]
    availableBrands: KeyValuePair<string, string>[]
    brands: IBrand[]
    markets: KeyValuePair<string, string>[]
    mediaTypes: KeyValuePair<string, string>[]
    years: number[]
}

interface IBrand {
    key: string
    description: string
    url: string
}

export let applicationContext = React.createContext({} as IApplicationContext);

let { Provider } = applicationContext;

let ApplicationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    let { isAuthenticated } = useContext(authContext);

    let [isLoading, setLoading] = useState(isAuthenticated === true);
    let [currentUser, setCurrentUser] = useState({} as IVMSUser);
    let [countries, setCountries] = useState([]);
    let [availableBrands, setAvailableBrands] = useState<KeyValuePair<string, string>[]>([]);
    let [brands, setBrands] = useState<IBrand[]>([]);
    let [markets, setMarkets] = useState([]);
    let [mediaTypes, setMediaTypes] = useState([]);
    let [years, setYears] = useState<number[]>([]);


    useEffect(() => {
        getBrands();
    }, []);


    useEffect(() => {
        if (isAuthenticated === true)
            getReferenceData();
        else
            clearReferenceData();

    }, [isAuthenticated]);


    let getReferenceData = async () => {
        try {
            setLoading(true);

            let res = await axios.get('/api/ReferenceData/GetReferenceData');

            let { item } = res.data;
            setCurrentUser(item.currentUser);
            setCountries(item.countries);
            setMarkets(item.markets);
            setMediaTypes(item.mediaTypes);
            setAvailableBrands(item.availableBrands);

            initializeReactGA(item.currentUser.countryCode)

            for (let index = 2019; index <= moment().year(); index++) {
                years.push(index);
            }
            setYears(years);

        } catch (err) { }
        finally {
            setLoading(false);
        }
    }

    const clearReferenceData = () => {
        setCurrentUser({} as IVMSUser);
        setCountries([]);
        setMarkets([]);
        setMediaTypes([]);
        setAvailableBrands([]);
        initializeReactGA("XX");
    }

    let getBrands = async () => {
        try {
            setLoading(true);

            let res = await axios.get('/api/ReferenceData/GetBrands');
            setBrands(res.data.items);
        } catch (err) {
        }
        finally {
            setLoading(false);
        }
    }

    let initializeReactGA = (countryCode: string) => {
        switch (countryCode) {
            case "DE":
                ReactGA.initialize("UA-12213999-2");
                break;

            case "FR":
                ReactGA.initialize("UA-12213999-3");
                break;

            case "IT":
                ReactGA.initialize("UA-12213999-4");
                break;

            case "JP":
                ReactGA.initialize("UA-12213999-5");
                break;

            case "UK":
                ReactGA.initialize("UA-12213999-6");
                break;

            case "US":
                ReactGA.initialize("UA-12213999-7");
                break;

            case "CN":
                ReactGA.initialize("UA-12213999-8");
                break;

            case "HK":
                ReactGA.initialize("UA-12213999-9");
                break;

            default:
                ReactGA.initialize("UA-12213999-1");
                break;
        }
    }

    return (
        <Provider value={{
            isMobile,
            isLoading,
            currentUser,
            countries,
            brands,
            availableBrands,
            markets,
            mediaTypes,
            years
        }}>
            {children}
        </Provider>
    )
}

export default ApplicationProvider;