import { LocalizationKey } from 'helpers/Enums';
import { applicationContext, authContext } from 'helpers/services';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

let Navbar = () => {
    let { isAuthenticated } = useContext(authContext);
    let { currentUser } = useContext(applicationContext);
    let navigate = useNavigate();
    let { formatMessage } = useIntl();

    let [showMenu, setShowMenu] = useState<boolean>(false);

    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-white bg-white">

                <a className="logo navbar-brand vitec" style={{ cursor: 'pointer' }} onClick={() => navigate(`/`)}>
                    <img
                        src="/images/logos/Videndum_Media_Solutions_Logo.svg"
                        alt="Videndum media distribution Logo"
                        className="header-logo"
                    />
                </a>
                {isAuthenticated === true &&
                    <>
                        <button className={`navbar-toggler btn btn-dark bg-dark ${showMenu === true ? '' : 'collapsed'}`}
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded={showMenu === true ? "true" : "false"}
                            aria-label="Toggle navigation"
                            onClick={() => setShowMenu(!showMenu)}
                        >
                            <i className="fa fa-bars text-white" />
                        </button>
                        <div className={`navbar-collapse ${showMenu === true ? 'collapse show' : 'collapse'}`} id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link" to='/home/marketingtools'>
                                        {formatMessage({ id: LocalizationKey.MarketingTools })}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to='/home/productdata'>
                                        {formatMessage({ id: LocalizationKey.ProductData })}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to='/home/visualpricelist'>
                                        {formatMessage({ id: LocalizationKey.VisualPriceList })}
                                    </Link>
                                </li>
                                {currentUser.canManageUsers === true &&
                                    <li className="nav-item">
                                        <Link className="nav-link" to='/admin/users'>
                                            Users
                                        </Link>
                                    </li>
                                }
                                {currentUser.isAdmin === true &&
                                    <li className="nav-item">
                                        <Link className="nav-link" to='/admin/adminusers'>
                                            Admins
                                        </Link>
                                    </li>
                                }
                            </ul>
                        </div>
                    </>
                }
            </nav>
        </header>
    );
}

export default Navbar;