import React, { useContext } from "react";
import { IMarketingTool } from "../MarketingToolsContext";
import Moment from 'react-moment';
import { useNavigate } from "react-router-dom";
import { applicationContext } from "helpers/services";
import { useIntl } from "react-intl";
import { LocalizationKey } from "helpers/Enums";

interface IMarketingToolElementProps {
    marketingTool: IMarketingTool
}
let MarketingToolElement = ({ marketingTool }: IMarketingToolElementProps) => {
    let navigate = useNavigate();
    let appService = useContext(applicationContext);
    let { formatMessage } = useIntl();

    return (
        <div className="product" onClick={() => navigate(`/home/marketingtools/detail/${marketingTool.marketingToolId}`)}>
            <div className="description">
                <div className="grid-description">
                    <div className="container-img">
                        <img src={`${process.env.REACT_APP_MEDIA_CDN}/${marketingTool.mainMediaId}?heigth=200&width=200`} />
                    </div>
                    <h3 className="incaps">{marketingTool.title}</h3>
                    <p className="product-type">
                        <span>{appService.availableBrands.getValueByKey(marketingTool.brand)}</span>
                        &nbsp;−&nbsp;
                        {marketingTool.date &&
                            <span><Moment date={marketingTool.date} format="LL" /></span>
                        }
                    </p>
                </div>
            </div>
            <div className="loadMoreBlog text-center">
                <button type="button" className="btn btn-block">{formatMessage({ id: LocalizationKey.ViewMore})}</button>
            </div>
        </div>

    );
}

export default MarketingToolElement;