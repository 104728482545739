import { useContext, useState } from "react";
import { productDataContext } from "../ProductDataContext";
import { useIntl } from "react-intl";
import { LocalizationKey } from "helpers/Enums";

interface IProductDataContent {
    brandName: string
    files: string[]
}

let Results = ({ brandName, files }: IProductDataContent) => {
    let { formatMessage } = useIntl();

    let service = useContext(productDataContext);

    let [currentOver, setCurrentOver] = useState('');

    return (
        <div className="col">
            <h3>{brandName}</h3>
            <ul>
                {files.map(file =>
                    <li
                        style={{ whiteSpace: 'nowrap' }}
                        key={`${file}.json`}
                        onClick={async () => await service.downloadProductData(file, brandName, service.selectedLanguage, "json")}
                    >
                        <a
                            style={{ cursor: 'pointer', textDecoration: currentOver === `${file}.json` ? 'underline' : 'none' }}
                            onMouseEnter={() => setCurrentOver(`${file}.json`)}
                            onMouseLeave={() => setCurrentOver('')}
                        >
                            {file}-{brandName}-{service.selectedLanguage}.json
                        </a>
                    </li>
                )}
                <li style={{ cursor: 'default' }}>&nbsp;</li>
                {files.map(file =>
                    <li
                        style={{ whiteSpace: 'nowrap' }}
                        key={`${file}.xlsx`}
                        onClick={async () => await service.downloadProductData(file, brandName, service.selectedLanguage, "xlsx")}
                    >
                        <a
                            style={{ cursor: 'pointer', textDecoration: currentOver === `${file}.xlsx` ? 'underline' : 'none' }}
                            onMouseEnter={() => setCurrentOver(`${file}.xlsx`)}
                            onMouseLeave={() => setCurrentOver('')}
                        >
                            {file}-{brandName}-{service.selectedLanguage}.xlsx
                        </a>
                    </li>
                )}
                <li style={{ cursor: 'default' }}>&nbsp;</li>
                {files.map(file =>
                    <li
                        style={{ whiteSpace: 'nowrap' }}
                        key={`${file}.xml`}
                        onClick={async () => await service.downloadProductData(file, brandName, service.selectedLanguage, "xml")}
                    >
                        <a
                            style={{ cursor: 'pointer', textDecoration: currentOver === `${file}.xml` ? 'underline' : 'none' }}
                            onMouseEnter={() => setCurrentOver(`${file}.xml`)}
                            onMouseLeave={() => setCurrentOver('')}
                        >
                            {file}-{brandName}-{service.selectedLanguage}.xml
                        </a>
                    </li>
                )}
                <li style={{ cursor: 'default' }}>&nbsp;</li>
                <li onClick={async () => await service.downloadProductData("ProductData", brandName, "Images", "zip")}>
                    <a
                        style={{ cursor: 'pointer', textDecoration: currentOver === 'images.zip' ? 'underline' : 'none' }}
                        onMouseEnter={() => setCurrentOver('images.zip')}
                        onMouseLeave={() => setCurrentOver('')}
                    >
                        {formatMessage({ id: LocalizationKey.ProductImages })}
                    </a>
                </li>
            </ul>
        </div>
    );

}

export default Results;