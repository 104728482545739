import { applicationContext } from "helpers/services";
import { useContext } from "react";

let HomeBrands = () => {
    let { brands } = useContext(applicationContext);

    return (
        <>
            {brands.map(brand =>
                <a className={`logo logo-${brand.key.toLowerCase()}-lg-c`} key={brand.key} href={brand.url} target='_blank'><span /></a>
            )}
        </>
    )
}

export default HomeBrands;