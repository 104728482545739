import AdminUsers from './AdminUsers';
import AdminUsersProvider from './AdminUsersContext';

let Index = () => {
    return (
        <AdminUsersProvider>
            <AdminUsers />
        </AdminUsersProvider>
    )
}

export default Index;