import VisualPriceList from "./VisualPriceList";
import VisualPriceListProvider from "./VisualPriceListContext"


let Index = () => {

    return (
        <VisualPriceListProvider>
            <VisualPriceList />
        </VisualPriceListProvider>
    );

}

export default Index;