import axios from 'axios';
import { UserType } from 'helpers/Enums';
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

interface IContext {
    isLoading: boolean
    isExporting: boolean
    recordCount: number
    search: ISearch
    users: IUser[]

    exportUsers: () => Promise<void>
    updateSearch: (newSearch: ISearch) => void
    doSearch: (values: ISearch) => void
    searchUsers: (searchRequest: ISearch) => Promise<void>
    handleChangePage: (event: any, page: number) => void
    deleteUser: (userId: string) => Promise<void>
    resendPassword: (userId: string, existInCognito: boolean) => Promise<void>
}

interface IUser {
    id: string
    email: string
    firstName: string
    lastName: string
    companyName: string
    extId: string
    existInCognito: boolean
}

interface ISearch {
    searchText: string
    extId: string
    countryCode: string
    brands: string[]
    markets: string[]
    pageNr: number
    pageSize: number
}

export let usersContext = React.createContext({} as IContext);

let { Provider } = usersContext;

let UsersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let location = useLocation();
    let navigate = useNavigate();

    const defaultSearch: ISearch = {
        searchText: '',
        brands: [],
        markets: [],
        countryCode: '',
        extId: '',
        pageNr: 0,
        pageSize: 20
    }

    let [isLoading, setLoading] = useState(false);
    let [isExporting, setExporting] = useState(false);
    let [search, setSearch] = useState<ISearch>(defaultSearch);
    let [recordCount, setTotalRecords] = useState(0);
    let [users, setUsers] = useState<IUser[]>([]);


    useEffect(() => {
        if (location.state)
            processState(location.state);

    }, [location.state])


    if (!location.state)
        return <Navigate to={location.pathname} state={{ search }} />


    let processState = (state: any) => {
        let request = { ...search }

        if (state.search) {
            request = {
                ...request,
                ...state.search
            }
        }

        setSearch(request);
        searchUsers(request);
    }

    let doSearch = (values: ISearch) => {
        let request = {
            ...values,
            pageNr: values.pageNr > 0 ? 0 : values.pageNr
        }

        navigate(location.pathname, { state: { search: request } });
    }

    let updateSearch = (newSearch: ISearch) => {
        setSearch(newSearch);
    }

    let searchUsers = async (searchRequest: ISearch) => {
        try {
            setLoading(true);
            let response = await axios.post('/api/UserVisibility/SearchUsers', searchRequest);

            setUsers(response.data.items);
            setTotalRecords(response.data.recordCount);

        } catch (error) { }
        finally {
            setLoading(false);
        }
    }

    let exportUsers = async () => {
        try {
            setExporting(true);
            let response = await axios.post('/api/UserVisibility/ExportUsers', search, { responseType: "blob" });

            let filename = ``;
            let disposition = response.headers['content-disposition'];

            if (disposition && disposition.indexOf('attachment') !== -1) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1])
                    filename = matches[1].replace(/['"]/g, '');
            }


            let url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

        } catch (error) { }
        finally {
            setExporting(false);
        }
    }

    let handleChangePage = (event: any, page: number) => {
        let request = {
            ...search,
            pageNr: page
        }

        window.scrollTo(0, 0);
        navigate(location.pathname, { state: { search: request } });
    }

    let deleteUser = async (userId: string) => {
        if (window.confirm("Do you want to delete this user?") === false)
            return;

        try {
            let res = await axios.delete(`/api/UserVisibility/DeleteUser/${userId}`);
            if (res.data.hasDeleted === true)
                setUsers([...users.filter(x => x.id !== userId)]);

        } catch (err) {

        }
    }

    let resendPassword = async (userId: string, existInCognito: boolean) => {
        if (window.confirm(`Do you want to ${existInCognito ? 'reset password to' : 'create in cognito'} this user?`) === false)
            return;

        try {
            let res = await axios.get(`/api/UserVisibility/ResendPassword`, { params: { userId: userId } });

        } catch (err) {

        }
    }


    return (
        <Provider
            value={{
                isLoading,
                isExporting,
                recordCount,
                search,
                users,

                updateSearch,
                exportUsers,
                doSearch,
                searchUsers,
                handleChangePage,
                deleteUser,
                resendPassword
            }}
        >
            {children}
        </Provider>
    )
}

export default UsersProvider;