import { Typography, Container, Link } from '@mui/material';
import { authContext } from 'helpers/services';
import { useContext } from 'react';

const Error401 = (props: any) => {
  let authService = useContext(authContext);

  return (
    <Container maxWidth="lg" style={{ textAlign: 'center' }}>
      <Typography align="center" variant="h2" sx={{ mt: 7 }}>
        401: Sorry, we're unable to authenticate you
      </Typography>
      <Typography align="center" variant="subtitle2" sx={{ mb: 8.2 }}>
        Your account is not enabled in our system or you did not choose the right login option.&nbsp;
        <Link style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => authService.signOut()}>Please logout and try again</Link>
      </Typography>
      <img alt="Under development" src="/images/errors/401.svg" height={500} />
    </Container>
  );
};

export default Error401;
