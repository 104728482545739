import MarketingTools from "./MarketingTools";
import MarketingToolsProvider from "./MarketingToolsContext"


let Index = () => {

    return (
        <MarketingToolsProvider>
            <MarketingTools />
        </MarketingToolsProvider>
    );

}

export default Index;