import { Layout } from 'components';
import { Outlet } from "react-router-dom"

let PublicRoute = () => {
    return (
        <Layout>
            <Outlet />
        </Layout>
    );
}

export default PublicRoute;