import { Grid, Typography, Button, TextField as MuiTextField, FormControlLabel, Checkbox, Alert, CircularProgress } from '@mui/material';
import { Form, Field, useFormikContext } from 'formik';
import { Autocomplete, TextField } from 'formik-mui';
import { useContext, useEffect, useState } from 'react';
import { applicationContext } from 'helpers/services';
import { User } from '../UserDetailsContext';
import { ContactType } from 'helpers/Enums';
import KeyValuePair from 'helpers/KeyValuePair';

interface IProps {
    isNew: boolean
}

let UserForm = ({ isNew }: IProps) => {
    let appService = useContext(applicationContext);

    let form = useFormikContext<User>();

    const contactTypes = [
        new KeyValuePair<string, string>(ContactType.Dealer, "Dealer"),
        new KeyValuePair<string, string>(ContactType.Distributor, "Distributor"),
        new KeyValuePair<string, string>(ContactType.MediaAgency, "Media agency"),
        new KeyValuePair<string, string>(ContactType.Photographer, "Photographer")
    ]

    let [validSfContactTypes, setValidSfContactTypes] = useState(true);

    useEffect(() => {
        let validSfContactTypes = form.values.contactTypes.findIndex(x => x !== ContactType.Dealer && x !== ContactType.Distributor) === -1;
        setValidSfContactTypes(validSfContactTypes);
    }, [form.values.contactTypes])


    return (
        <Form>
            <Grid container alignItems='center'>
                <Grid item xs>
                    <Typography variant="h4">{isNew ? "New user" : "Edit user"}</Typography>
                </Grid>
                <Grid item>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={form.isSubmitting}
                    >
                        {form.isSubmitting && <><CircularProgress size={16} />&nbsp;</>}Save
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Field
                        component={TextField}
                        name="email"
                        label="Email"
                        disabled={isNew === false}
                        fullWidth
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="firstName"
                        label="First name"
                        fullWidth
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="lastName"
                        label="Last name"
                        fullWidth
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="companyName"
                        label="Company name"
                        fullWidth
                        size='small'
                    />
                </Grid>

                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="extId"
                        label="M3 Id"
                        fullWidth
                        size='small'
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography><b>Visibility</b></Typography>
                </Grid>
                <Grid item xs={12}>
                    <Field
                        name="countryCode"
                        component={Autocomplete}
                        options={appService.countries.map(x => x.key)}
                        getOptionLabel={(option: string) => appService.countries.getValueByKey(option) || ''}
                        renderInput={(params: any) =>
                            <MuiTextField
                                {...params}
                                size="small"
                                error={form.touched['countryCode'] && form.errors['countryCode']}
                                helperText={form.errors['countryCode']}
                                label="Country"
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        multiple
                        disableCloseOnSelect
                        name="brands"
                        component={Autocomplete}
                        options={appService.availableBrands.map(x => x.key)}
                        getOptionLabel={(option: string) => appService.availableBrands.getValueByKey(option)}
                        renderInput={(params: any) =>
                            <MuiTextField
                                {...params}
                                size="small"
                                error={form.touched['brands'] && !!form.errors['brands']}
                                helperText={form.errors['brands']}
                                label="Brands"
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        multiple
                        disableCloseOnSelect
                        name="markets"
                        component={Autocomplete}
                        options={appService.markets.map(x => x.key)}
                        getOptionLabel={(option: string) => appService.markets.getValueByKey(option)}
                        renderInput={(params: any) =>
                            <MuiTextField
                                {...params}
                                size="small"
                                error={form.touched['markets'] && !!form.errors['markets']}
                                helperText={form.errors['markets']}
                                label="Markets"
                            />
                        }
                    />
                </Grid>


                <Grid item xs={12}>
                    <Typography><b>Salesforce</b></Typography>
                </Grid>
                <Grid item xs={12}>
                    <Field
                        multiple
                        disableCloseOnSelect
                        name="contactTypes"
                        component={Autocomplete}
                        options={contactTypes.map(x => x.key)}
                        getOptionLabel={(option: string) => contactTypes.getValueByKey(option)}
                        renderInput={(params: any) =>
                            <MuiTextField
                                {...params}
                                size="small"
                                error={form.touched['contactTypes'] && !!form.errors['contactTypes']}
                                helperText={form.errors['contactTypes']}
                                label="Contact types"
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    {(form.values.canUpdateSalesforceAccount === false || (form.values.canUpdateSalesforceAccount === true && validSfContactTypes === false)) &&
                        <Alert severity="info">User is managed on Salesforce. Please open a ticket to WebTeam</Alert>
                    }
                    {form.values.canUpdateSalesforceAccount === true && validSfContactTypes === true &&
                        <FormControlLabel
                            label="Salesforce integration"
                            name="salesforceIntegration"
                            value={form.values.salesforceIntegration}
                            checked={form.values.salesforceIntegration}
                            onChange={(e: any) => form.setFieldValue('salesforceIntegration', e.target.checked)}
                            control={<Field type="checkbox" name="salesforceIntegration" component={Checkbox} />}
                        />
                    }
                </Grid>
            </Grid>
        </Form>
    )
}

export default UserForm;