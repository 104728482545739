import axios from "axios";
import { LocalizationKey } from "helpers/Enums";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface IContext {
    alert: IAlert
}

interface IAlert {
    isProcessing: boolean
    severity?: "error" | "warning" | "success" | "info"
    messageKey: LocalizationKey
}

export let downloadContext = React.createContext({} as IContext);

let { Provider } = downloadContext;

let DownloadRequestProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let { id } = useParams<{ id: string }>();


    let [alert, setAlert] = useState<IAlert>({
        isProcessing: true,
        severity: 'warning',
        messageKey: LocalizationKey.DownloadInProgress
    });


    useEffect(() => {
        let getDownloadRequest = async () => {
            try {
                let res = await axios.get('/api/DownloadRequest/GetDownloadRequest', { params: { id } });

                window.location.href = res.data;

                setAlert({
                    isProcessing: false,
                    severity: 'success',
                    messageKey: LocalizationKey.DownloadSuccess
                })

            } catch (err) {
                setAlert({
                    isProcessing: false,
                    severity: 'error',
                    messageKey: LocalizationKey.DownloadError
                })
            }
        }

        getDownloadRequest();
    }, [id])


    return (
        <Provider value={{ alert }}>
            {children}
        </Provider>
    )
}

export default DownloadRequestProvider;