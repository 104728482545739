import { applicationContext } from "helpers/services";
import { useContext } from "react";
import { marketingToolsContext } from "../MarketingToolsContext";
import { useIntl } from "react-intl";
import { LocalizationKey } from "helpers/Enums";

let Filters = () => {
    let service = useContext(marketingToolsContext);
    let appService = useContext(applicationContext);
    let { formatMessage } = useIntl();

    let handleChangeBrand = (value: string) => {
        let idx = service.search.brands.findIndex(x => x === value);

        if (idx === -1)
            service.search.brands.push(value);
        else
            service.search.brands.splice(idx, 1);

        service.updateSearch({ ...service.search, brands: service.search.brands });

        service.doSearch(service.search);
    }

    let handleChangeCategory = (value: string) => {
        let idx = service.search.markets.findIndex(x => x === value);

        if (idx === -1)
            service.search.markets.push(value);
        else
            service.search.markets.splice(idx, 1);

        service.updateSearch({ ...service.search, markets: service.search.markets });

        service.doSearch(service.search);
    }

    let handleChangeYear = (value: number) => {
        let idx = service.search.years.findIndex(x => x === value);

        if (idx === -1)
            service.search.years.push(value);
        else
            service.search.years.splice(idx, 1);

        service.updateSearch({ ...service.search, years: service.search.years });

        service.doSearch(service.search);
    }

    return (
        <div className="filtering">
            <div className="filtering__search-bar">
                <button className="btn btn-link" onClick={() => service.doSearch(service.search)}>
                    <i className="fa fa-search" />
                </button>
                <input
                    type="text"
                    placeholder={formatMessage({ id: LocalizationKey.SearchByTitle })}
                    value={service.search.text}
                    onChange={(e) => service.updateSearch({ ...service.search, text: e.target.value })}
                    onKeyDown={(e) => { if (e.key === 'Enter') service.doSearch(service.search) }}
                />
                <label>{formatMessage({ id: LocalizationKey.SearchByTitle })}</label>
            </div>
            <div className="filtering__filters">
                <div className="list-filters">
                    <button type="button" className="btn btn-link">{formatMessage({ id: LocalizationKey.Filters })}</button>

                    <span>
                        <input type="radio" name="toggle-filters" className="openfilters" id="toggle-check-brands" />
                        <input type="radio" name="toggle-filters" className="closefilters" />
                        <label htmlFor="toggle-check-brands">{formatMessage({ id: LocalizationKey.Brands })}</label>
                        <div>
                            <ul className="filters-list">
                                {appService.availableBrands.map(brand =>
                                    <li key={brand.key}>
                                        <label style={{ cursor: 'pointer', fontSize: 'medium', fontWeight: 'normal' }}>
                                            <input
                                                type="checkbox"
                                                name="brand"
                                                value={brand.key}
                                                checked={service.search.brands.findIndex(x => x === brand.key) !== -1}
                                                onChange={(e: any) => handleChangeBrand(e.target.value)}
                                            />
                                            <span>{brand.value}</span>
                                        </label>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </span>

                    <span>
                        <input type="radio" name="toggle-filters" className="openfilters" id="toggle-check-productcategories" />
                        <input type="radio" name="toggle-filters" className="closefilters" />
                        <label htmlFor="toggle-check-productcategories">{formatMessage({ id: LocalizationKey.Category })}</label>
                        <div>
                            <ul className="filters-list">
                                {appService.markets.map(market =>
                                    <li key={market.key}>
                                        <label style={{ cursor: 'pointer', fontSize: 'medium', fontWeight: 'normal' }}>
                                            <input
                                                type="checkbox"
                                                name="market"
                                                value={market.key}
                                                checked={service.search.markets.findIndex(x => x === market.key) !== -1}
                                                onChange={(e: any) => handleChangeCategory(e.target.value)}
                                            />
                                            <span>{market.value}</span>
                                        </label>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </span>

                    <span>
                        <input type="radio" name="toggle-filters" className="openfilters" id="toggle-check-year" />
                        <input type="radio" name="toggle-filters" className="closefilters" />
                        <label htmlFor="toggle-check-year">{formatMessage({ id: LocalizationKey.Year })}</label>
                        <div>
                            <ul className="filters-list">
                                {appService.years.map(year =>
                                    <li key={year}>
                                        <label style={{ cursor: 'pointer', fontSize: 'medium', fontWeight: 'normal' }}>
                                            <input
                                                type="checkbox"
                                                name="year"
                                                value={year}
                                                checked={service.search.years.findIndex(x => x === year) !== -1}
                                                onChange={(e: any) => handleChangeYear(Number(e.target.value))}
                                            />
                                            <span>{year}</span>
                                        </label>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </span>
                </div>
                <div className="filers-bar">
                    <ul className="filters-list filters-actives">
                        {appService.availableBrands
                            .filter(x => service.search.brands.findIndex(y => y === x.key) !== -1)
                            .map(brand =>
                                <li key={brand.key}>
                                    <label style={{ cursor: 'pointer', fontSize: 'medium', fontWeight: 'normal' }}>
                                        <input
                                            type="checkbox"
                                            name="brand"
                                            value={brand.key}
                                            checked={service.search.brands.findIndex(x => x === brand.key) !== -1}
                                            onChange={(e: any) => handleChangeBrand(e.target.value)}
                                        />
                                        <span>{brand.value}</span>
                                    </label>
                                </li>
                            )}

                        {appService.markets
                            .filter(x => service.search.markets.findIndex(y => y === x.key) !== -1)
                            .map(market =>
                                <li key={market.key}>
                                    <label style={{ cursor: 'pointer', fontSize: 'medium', fontWeight: 'normal' }}>
                                        <input
                                            type="checkbox"
                                            name="market"
                                            value={market.key}
                                            checked={service.search.markets.findIndex(x => x === market.key) !== -1}
                                            onChange={(e: any) => handleChangeCategory(e.target.value)}
                                        />
                                        <span>{market.value}</span>
                                    </label>
                                </li>
                            )}

                        {appService.years
                            .filter(x => service.search.years.findIndex(y => y === x) !== -1)
                            .map(year =>
                                <li key={year}>
                                    <label style={{ cursor: 'pointer', fontSize: 'medium', fontWeight: 'normal' }}>
                                        <input
                                            type="checkbox"
                                            name="year"
                                            value={year}
                                            checked={service.search.years.findIndex(x => x === year) !== -1}
                                            onChange={(e: any) => handleChangeYear(Number(e.target.value))}
                                        />
                                        <span>{year}</span>
                                    </label>
                                </li>
                            )}
                    </ul>
                </div>
            </div>
        </div >
    );

}

export default Filters;