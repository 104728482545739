import { useContext } from "react";
import { visualPriceListContext } from "../VisualPriceListContext";
import Pagination from "helpers/Pagination";
import { useIntl } from "react-intl";
import { LocalizationKey } from "helpers/Enums";
import { applicationContext } from "helpers/services";
import { NumericFormat } from "react-number-format";


let TableView = () => {
    let { brands } = useContext(applicationContext);
    let service = useContext(visualPriceListContext);
    let { formatMessage } = useIntl();

    return (
        <div className="table active">
            <div className="row">
                <div className="w-100">
                    <div className="float-left">
                        <h6>
                            <span className="badge badge-primary">
                                {formatMessage({ id: LocalizationKey.Found })} <span>{service.recordCount}</span> {formatMessage({ id: LocalizationKey.Products }).toLowerCase()}
                            </span>
                        </h6>
                    </div>
                    <div className="float-right">
                        <Pagination
                            numberOfItems={service.recordCount}
                            pageNr={service.search.pageNr}
                            onPageChange={(pageNr: number) => service.handleChangePage({} as any, pageNr)}
                        />
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-stripped table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>{formatMessage({ id: LocalizationKey.Product })}</th>
                                <th className="text-center">{formatMessage({ id: LocalizationKey.MasterCarton })}</th>
                                <th>EAN - UPC</th>
                                <th className="text-right">{formatMessage({ id: LocalizationKey.SuggestedPrice })}</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {service.products.map(product =>
                                <tr key={product.sku}>
                                    <td style={{ textAlign: 'center', fontSize: '35px ' }}>
                                        <img
                                            width={50}
                                            src={`${process.env.REACT_APP_MEDIA_CDN}/byproduct?sku=${product.sku}&heigth=200&width=200`}
                                        />
                                    </td>
                                    <td>
                                        <b>{brands.getValueByKey(product.brandCode, 'key', 'description')}&nbsp;{product.sku}</b>
                                        <br />
                                        <span>{product.description}</span>
                                    </td>
                                    <td className="text-center">{product.itemMCQuantity}</td>
                                    <td>
                                        <span>{product.ean}</span>&nbsp;·&nbsp;<span>{product.upc}</span>
                                    </td>
                                    <td className="text-right">
                                        {product.suggestedPrice !== 0 &&
                                            <NumericFormat
                                                className="price-number"
                                                displayType="text"
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                decimalScale={2}
                                                fixedDecimalScale
                                                prefix={`${product.currency} `}
                                                value={product.suggestedPrice}
                                            />
                                        }
                                    </td>
                                    <td style={{ whiteSpace: 'nowrap' }} align="right">
                                        <a
                                            className="btn btn-block"
                                            target="_blank"
                                            href={`/home/productdetail?sku=${product.sku}`}
                                        >
                                            <i className="fa fa-search" />
                                        </a>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="w-100">
                    <div className="float-left">
                        <h6>
                            <span className="badge badge-primary">
                                {formatMessage({ id: LocalizationKey.Found })} <span>{service.recordCount}</span> {formatMessage({ id: LocalizationKey.Products }).toLowerCase()}
                            </span>
                        </h6>
                    </div>
                    <div className="float-right">
                        <Pagination
                            numberOfItems={service.recordCount}
                            pageNr={service.search.pageNr}
                            onPageChange={(pageNr: number) => service.handleChangePage({} as any, pageNr)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TableView;