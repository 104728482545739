import { Loading, Page } from "components"
import { Formik } from 'formik';
import { useContext } from "react";
import { adminUserContext, adminUserSchema } from "./AdminUserDetailsContext";
import { UserForm } from './components';

interface IProps {
    isNew: boolean
}

let UserDetails = ({ isNew }: IProps) => {
    let service = useContext(adminUserContext);

    return (
        <Page title={isNew ? "New admin user" : "Edit admin user"} maxWidth="md">
            {service.isLoading === true && <Loading />}
            {service.isLoading === false &&
                <Formik
                    enableReinitialize={true}
                    initialValues={service.adminUser}
                    validationSchema={adminUserSchema}
                    onSubmit={async (values, action) => {
                        await service.saveAdminUser(values);
                        action.setSubmitting(false);
                    }}
                >
                    <UserForm isNew={isNew} />
                </Formik>
            }
        </Page>
    )
}

export default UserDetails;