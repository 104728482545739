import { useContext } from "react";
import { visualPriceListContext } from "../VisualPriceListContext";
import { applicationContext } from "helpers/services";
import { useIntl } from "react-intl";
import { LocalizationKey } from "helpers/Enums";

let Filters = () => {
    let service = useContext(visualPriceListContext);
    let appService = useContext(applicationContext);
    let { formatMessage } = useIntl();

    return (
        <div className="filtering" style={{ marginTop: 1 }}>
            <div className="filtering__multiple-search-bar">
                <form className="row no-gutters">
                    <div className="col-6 col-md-3">
                        <div>
                            <select
                                className=""
                                value={service.search.brand}
                                onChange={(e: any) => service.updateSearch({ ...service.search, brand: e.target.value })}
                            >
                                <option value="">{formatMessage({ id: LocalizationKey.Any })}</option>
                                {appService.availableBrands.map(brand =>
                                    <option key={brand.key} value={brand.key}>{brand.value}</option>
                                )}
                            </select>
                            <label className="l">{formatMessage({ id: LocalizationKey.Brand })}</label>

                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div>
                            <input
                                type="text"
                                value={service.search.productCode}
                                onChange={(e: any) => service.updateSearch({ ...service.search, productCode: e.target.value })}
                                onKeyDown={(e) => { if (e.key === 'Enter') service.doSearch(service.search) }}
                            />
                            <label>{formatMessage({ id: LocalizationKey.ProductCode })}</label>
                        </div>
                    </div>
                    <div className="col-6 col-md">
                        <div>
                            <input
                                type="text"
                                value={service.search.productDescription}
                                onChange={(e: any) => service.updateSearch({ ...service.search, productDescription: e.target.value })}
                                onKeyDown={(e) => { if (e.key === 'Enter') service.doSearch(service.search) }}
                            />
                            <label>{formatMessage({ id: LocalizationKey.ProductDescription })}</label>
                        </div>
                    </div>
                    <div className="col-6 col-md-auto">
                        <button
                            type="button"
                            onClick={() => service.doSearch(service.search)}
                            className="btn btn-primary btn-lg btn-block pr-5 pl-5"
                        >
                            {formatMessage({ id: LocalizationKey.Search })}
                        </button>
                    </div>
                </form>
            </div>
            <div className="filtering__filters__vispricelist">
                <div className="">
                    <p className="change-view-flat">
                        <span>{formatMessage({ id: LocalizationKey.ViewBy })}&nbsp;:&nbsp;</span>
                        <a className={service.viewType === 'table' ? 'active' : ''} onClick={() => service.updateViewType('table')}>
                            <i className="fa fa-th-list" />
                        </a>
                        &nbsp;|&nbsp;
                        <a className={service.viewType === 'grid' ? 'active' : ''} onClick={() => service.updateViewType('grid')}>
                            <i className="fa fa-th-large" />
                        </a>
                    </p>
                </div>
            </div>
        </div >
    );

}

export default Filters;