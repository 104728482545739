import { Alert, Button, Grid, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { authContext } from "helpers/services";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from 'yup';

interface ILoginProps {
    returnUrl?: string
}

let Login = ({ returnUrl }: ILoginProps) => {
    let location = useLocation();
    let navigate = useNavigate();

    let authService = useContext(authContext);

    let loginSchema = yup.object({
        email: yup.string().default('').required('Email is mandatory field'),
        password: yup.string().default('').required('Password is mandatory field')
    })

    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState('');


    useEffect(() => {
        const prepareReturnUrl = () => {
            if (location.search) {
                const params = new URLSearchParams(location.search);

                const errors = params.get("error");
                if (errors)
                    setErrors(errors);
            }

            setLoading(false);
        }

        prepareReturnUrl();
    }, [])

    return (
        <Grid container textAlign='center'>
            <Grid item xs={12}>
                <Typography variant="h5">Distributor / Dealer access</Typography>
            </Grid>
            <Grid item xs>
                <Formik
                    initialValues={loginSchema.getDefault()}
                    validationSchema={loginSchema}
                    onSubmit={async (values, action) => {
                        setLoading(true);
                        let res = await authService.signInWithCredentials(values.email, values.password);

                        if (res === 'true' && returnUrl)
                            navigate(returnUrl);
                        else
                            setErrors("Incorrect username or password");

                        setLoading(false);
                    }}
                >
                    <Form>
                        <Grid container alignItems="center">
                            {errors &&
                                <Grid item xs={12}>
                                    <Typography textAlign='center' sx={{ color: '#FF0000' }}>{errors}</Typography>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    type="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    name="password"
                                    label="Password"
                                    variant="outlined"
                                    fullWidth
                                    type="password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Alert severity="info" style={{ textAlign: 'left' }}>
                                    From the 24th of July, we have improved our login system experience.<br />
                                    You can login with the same credentials you use to access into our Videndum Media Solutions websites.<br />
                                    In case you forgot your credentials, please click on “Forgot your password?”.
                                </Alert>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ width: '100%' }}
                                    disabled={isLoading}
                                >
                                    LOGIN
                                </Button>
                            </Grid>
                            <Grid item xs>
                                <Link to="/account/forgotpassword">
                                    Forgot your password?
                                </Link>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h5">Videndum employees</Typography>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    size="small"
                    sx={{ width: '100%' }}
                    onClick={async () => await authService.signInWithAd(returnUrl ?? '')}
                >
                    Login
                </Button>
            </Grid>
        </Grid>
    )
}

export default Login;