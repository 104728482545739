import { LocalizationKey } from "helpers/Enums";

let deDE = {
    /*  ERROR 403  */
    [LocalizationKey.Error403]: "Irgendetwas stimmt mit Ihrem Benutzer nicht",
    [LocalizationKey.Error403Note]: "Versuchen Sie, sich abzumelden und erneut anzumelden: Wenn sich nichts ändert, wenden Sie sich an den technischen Support.",

    /*  ERROR 404  */
    [LocalizationKey.Error404]: "404: Die Seite, die Sie suchen, ist nicht hier",
    [LocalizationKey.Error404Note]: "Sie haben entweder eine schattige Route ausprobiert oder sind aus Versehen hierher gekommen. Versuchen Sie in jedem Fall, die Navigation zu verwenden",
    
    /*  GENERAL  */
    [LocalizationKey.Hi]: "Hi",
    [LocalizationKey.Logout]: "Logout",
    [LocalizationKey.PrivacyLink]: "https://www.videndummediasolutions.com/de/datenschutz-bestimmungen/",
    [LocalizationKey.DownloadError]: "An error occurred while processing your request. Refresh this page to try again",
    [LocalizationKey.DownloadSuccess]: "Your request has been processed successfully",
    [LocalizationKey.DownloadInProgress]: "Your request is in progress",
    [LocalizationKey.RequestSent]: "Request sent",
    [LocalizationKey.OurBrands]: "Our brands",
    [LocalizationKey.NoResultsFound]: "No results found",
    [LocalizationKey.Markets]: "Markets",
    [LocalizationKey.Brand]: "Marke",
    [LocalizationKey.Brands]: "Marke",
    [LocalizationKey.ViewMore]: "Mehr anzeigen",
    [LocalizationKey.Products]: "Produkte",
    [LocalizationKey.DownloadAllImages]: "Download all images",
    [LocalizationKey.DownloadAllImagesNotes]: "We'll send a ZIP file to your email address {emailAddress}",
    [LocalizationKey.DownloadSelectedImages]: "Download selected images",
    [LocalizationKey.Download]: "Download",
    [LocalizationKey.ProductDetail]: "Product detail",
    [LocalizationKey.PhoneNumber]: "+39 0424 555855",
    [LocalizationKey.MarketingTools]: "Marketing tools",
    [LocalizationKey.Search]: "Suche",
    [LocalizationKey.Any]: "Any",
    [LocalizationKey.Previous]: "Zurück",
    [LocalizationKey.Next]: "Nächste",
    [LocalizationKey.Close]: "Close",
    [LocalizationKey.Confirm]: "Confirm",
    [LocalizationKey.Page]: "Page",
    [LocalizationKey.Of]: "of",

    /*  HOMEPAGE  */
    [LocalizationKey.HomepageWelcomeTo]: "WILLKOMMEN BEI VIDENDUM MEDIA DISTRIBUTION",
    [LocalizationKey.HomepageWelcomeAnonymus]: " Willkommen im {videndumBold}, ein spezieller Ort für unser HÃ¤ndler-Netzwerk.\nMelden Sie sich an, um Zugriff auf alle {productInfoBold} und {marketingToolsBold} zu erhalten, \ndie für den effektiven Verkauf unserer Produkte erforderlich sind und erhalten Sie zusÃ¤tzlich die \nneuesten Nachrichten aus der Videndum Media Solutions Welt.",
    [LocalizationKey.HomepageWelcomeAuthenticated]: "To get the most out of all the resources and services available please look at the{link}\nWe hope you will find this mini-site useful.",
    [LocalizationKey.HomepageAboutUs]: "über uns",
    [LocalizationKey.HomepageAboutUsLine1]: "Videndum Media Solutions entwickelt, produziert und vertreibt hochwertige Foto- und Videoprodukte wie Stative, Taschen, Filter und Lichtsysteme für Profi- und Einsteiger-Fotografen sowie Videofilmer.",
    [LocalizationKey.HomepageAboutUsLine2]: "Produkte der Videndum Media Solutions werden von Videndum Media Distribution in China, Frankreich, Deutschland, Hong Kong, Italien, Benelux, Japan, GroÃŸbritannien und den USA sowie einem qualifizierten unabhÃ¤ngigen Vertriebsnetz in Ã¼ber 65 LÃ¤ndern auf der ganzen Welt vertrieben. Die unÃ¼bertroffene StÃ¤rke des internationalen Vertriebsnetzes und die enge Zusammenarbeit zwischen dem Unternehmen und den HÃ¤ndlern sind elementar für den Erfolg der Landesniederlassungen.",
    [LocalizationKey.Videndum]: "Videndum Media Distributoren Bereich",
    [LocalizationKey.ProductInformation]: "Produktinformationen",
    [LocalizationKey.HomepageWelcomeMarketingTools]: "Marketing-Tools",

    /*  MARKETING TOOLS  */
    [LocalizationKey.HomepageMarketingTools]: "Marketing Tools",
    [LocalizationKey.Filters]: "Filters",
    [LocalizationKey.SearchByTitle]: "Search by title or sku...",
    [LocalizationKey.Category]: "Category",
    [LocalizationKey.Year]: "Jahr",
    [LocalizationKey.MarketingToolsFound]: "Marketing tools found",
    [LocalizationKey.ProductsInMarketingTool]: "Products in this Marketing Tool",
    [LocalizationKey.ShowAll]: "Alles anzeigen",
    [LocalizationKey.ShowLess]: "Show less",
    [LocalizationKey.BackToSearch]: "Back to search",
    [LocalizationKey.OpenInVimeo]: "Open in vimeo",

    /*  PRODUCT DATA  */
    [LocalizationKey.ProductData]: "Produktdaten",
    [LocalizationKey.ProductDataNotes]: "Auf unserer Plattform erhalten Sie für alle Produkte der Videndum Media Solutions technischen Daten und Spezifikationen.\nWenn Sie einen eigenen Online Shop eröffnen möchten, automatische Prozesse festlegen oder Preislisten für die Verwaltung Ihrer E-Commerce-Aktivitäten erstellen möchten, finden Sie hier alles, was dazu nötig ist.\nAlle Daten werden in Excel in zwei verschiedenen Formaten (JSON und XML-Dateien) zur Verfügung gestellt.",
    [LocalizationKey.ProductDataJsonInfo]: "Ist ein kleines, lesbares Format zum Strukturieren von Daten. Es dient in erster Linie dazu, Daten zwischen einem Server und einer Webanwendung als Alternative zu XML zu übertragen.\nSquarespace verwendet JSON zum Speichern und Organisieren von Websiteinhalten, die mit dem CMS erstellt wurden.",
    [LocalizationKey.ProductDataXmlInfo]: "Ist eine Auszeichnungssprache, die eine Reihe von Regeln für die Codierung von Dokumenten in einem Format definiert, das sowohl menschlich lesbar als auch maschinenlesbar ist. Die Ziele von XML betonen Einfachheit, Allgemeinheit und Benutzerfreundlichkeit im Internet. Es ist ein Textdatenformat mit starker Unterstützung über Unicode für verschiedene menschliche Sprachen.",
    [LocalizationKey.ProductImages]: "Product Images (zip file)",

    /*  VISUAL PRICE LIST  */
    [LocalizationKey.VisualPriceList]: "Visual price list",
    [LocalizationKey.ProductCode]: "Produkt Code",
    [LocalizationKey.ProductDescription]: "Produktbeschreibung",
    [LocalizationKey.ViewBy]: "View by",
    [LocalizationKey.Found]: "Gefunden",
    [LocalizationKey.Product]: "Produkt",
    [LocalizationKey.MasterCarton]: "Verpackungseinheit (Master Karton)",
    [LocalizationKey.SuggestedPrice]: "Preisempfehlung des Herstellers",
    [LocalizationKey.Loading]: "Lädt...",
    [LocalizationKey.TechnicalSpecifications]: "Technische Spezifikationen",
    [LocalizationKey.Specification]: "Spezifikation",
    [LocalizationKey.Value]: "Wert",
    [LocalizationKey.CountryOfOrigin]: "Herkunftsland",
}

export default deDE;