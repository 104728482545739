import { Grid, Typography, Button, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
import { Form, Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { AdminUser } from '../AdminUserDetailsContext';

interface IProps {
    isNew: boolean
}

let UserForm = ({ isNew }: IProps) => {
    let form = useFormikContext<AdminUser>();

    return (
        <Form>
            <Grid container alignItems='center'>
                <Grid item xs>
                    <Typography variant="h4">{isNew ? "New user" : "Edit user"}</Typography>
                </Grid>
                <Grid item>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={form.isSubmitting}
                    >
                        {form.isSubmitting && <><CircularProgress size={16} />&nbsp;</>}Save
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Field
                        component={TextField}
                        name="email"
                        label="Email"
                        disabled={isNew === false}
                        fullWidth
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="firstName"
                        label="First name"
                        fullWidth
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="lastName"
                        label="Last name"
                        fullWidth
                        size='small'
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography><b>Permissions</b></Typography>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        label="Can manage users"
                        name="canManageUsers"
                        value={form.values.canManageUsers}
                        checked={form.values.canManageUsers}
                        onChange={(e: any) => form.setFieldValue('canManageUsers', e.target.checked)}
                        control={<Field type="checkbox" name="canManageUsers" component={Checkbox} />}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        label="Is admin"
                        name="isAdmin"
                        value={form.values.isAdmin}
                        checked={form.values.isAdmin}
                        onChange={(e: any) => form.setFieldValue('isAdmin', e.target.checked)}
                        control={<Field type="checkbox" name="isAdmin" component={Checkbox} />}
                    />
                </Grid>
            </Grid>
        </Form>
    )
}

export default UserForm;