import { Grid } from '@mui/material';
import theme from 'assets/theme';


let FilterContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    return (
        <form onSubmit={(e: any) => e.preventDefault()}>
            <Grid container alignItems='flex-end' sx={{ marginBottom: theme.spacing(2) }}>
                {children}
            </Grid>
        </form>
    )
}

export default FilterContainer;