import React from 'react';
import { Container } from '@mui/material';
import theme from 'assets/theme';


interface IPage {
  title: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  children?: React.ReactNode
  className?: string
}

const Page = ({ title, maxWidth, children, className, ...rest }: IPage) => {
  return (
    <div {...rest} className={className}>
      {maxWidth &&
        <Container maxWidth={maxWidth} sx={{ paddingTop: theme.spacing(2) }}>
          {children}
        </Container>
      }
      {!maxWidth &&
        <>
          {children}
        </>
      }
    </div>
  );
};

export default Page;
