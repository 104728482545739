import { LocalizationKey } from "helpers/Enums";

let zhCN = {
    /*  ERROR 403  */
    [LocalizationKey.Error403]: "Something is wrong with your user",
    [LocalizationKey.Error403Note]: "Try to logout and login again: if nothing change ask for technical support.",

    /*  ERROR 404  */
    [LocalizationKey.Error404]: "404: The page you are looking for isn’t here",
    [LocalizationKey.Error404Note]: "You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation",

    /*  GENERAL  */
    [LocalizationKey.Hi]: "你好",
    [LocalizationKey.Logout]: "退出登录",
    [LocalizationKey.PrivacyLink]: "https://www.videndummediasolutions.com/en/privacy-policy/",
    [LocalizationKey.DownloadError]: "An error occurred while processing your request. Refresh this page to try again",
    [LocalizationKey.DownloadSuccess]: "Your request has been processed successfully",
    [LocalizationKey.DownloadInProgress]: "Your request is in progress",
    [LocalizationKey.RequestSent]: "Request sent",
    [LocalizationKey.OurBrands]: "我们的品牌",
    [LocalizationKey.NoResultsFound]: "No results found",
    [LocalizationKey.Markets]: "Markets",
    [LocalizationKey.Brand]: "产品品牌",
    [LocalizationKey.Brands]: "品牌",
    [LocalizationKey.ViewMore]: "View more",
    [LocalizationKey.Products]: "个产品",
    [LocalizationKey.DownloadAllImages]: "Download all images",
    [LocalizationKey.DownloadAllImagesNotes]: "We'll send a ZIP file to your email address {emailAddress}",
    [LocalizationKey.DownloadSelectedImages]: "Download selected images",
    [LocalizationKey.Download]: "Download",
    [LocalizationKey.ProductDetail]: "Product detail",
    [LocalizationKey.PhoneNumber]: "+39 0424 555855",
    [LocalizationKey.MarketingTools]: "推广资源",
    [LocalizationKey.Search]: "搜索",
    [LocalizationKey.Any]: "Any",
    [LocalizationKey.Previous]: "向前",
    [LocalizationKey.Next]: "向后",
    [LocalizationKey.Close]: "Close",
    [LocalizationKey.Confirm]: "确认",
    [LocalizationKey.Page]: "Page",
    [LocalizationKey.Of]: "of",

    /*  HOMEPAGE  */
    [LocalizationKey.HomepageWelcomeTo]: "欢迎来到威泰克影像",
    [LocalizationKey.HomepageWelcomeAnonymus]: "欢迎来到{videndumBold}, 这里是我们为经销商提供的专属空间。\n请登录账户以获取{productInfoBold} 和{marketingToolsBold} 以及来自威泰克影像解决方案的最新资讯。",
    [LocalizationKey.HomepageWelcomeAuthenticated]: "您可以通过{link}快速了解如何获取产品数据资源。\n我们希望这个迷你网站将对您有所帮助。",
    [LocalizationKey.HomepageAboutUs]: "关于我们",
    [LocalizationKey.HomepageAboutUsLine1]: "威泰克影像解决方案为专业摄影/摄像师和普通用户设计、制造和销售高端品牌的摄影和摄像设备，例如三脚架、摄影包、滤镜和照明设备。",
    [LocalizationKey.HomepageAboutUsLine2]: "威泰克影像解决方案的产品由自己的商贸公司销售，在中国，法国、德国、香港、意大利、日本、英国、美国和荷兰，加上独立分销商遍及世界65个国家和地区。国际分销网络无可比拟的实力和该公司与经销商的团队的努力是成功的关键因素。",
    [LocalizationKey.Videndum]: "Videndum Media Distributors Area",
    [LocalizationKey.ProductInformation]: "Product information",
    [LocalizationKey.HomepageWelcomeMarketingTools]: "Marketing tools",

    /*  MARKETING TOOLS  */
    [LocalizationKey.HomepageMarketingTools]: "推广资源",
    [LocalizationKey.Filters]: "Filters",
    [LocalizationKey.SearchByTitle]: "以标题搜索...",
    [LocalizationKey.Category]: "产品品类",
    [LocalizationKey.Year]: "发布年份",
    [LocalizationKey.MarketingToolsFound]: "Marketing tools found",
    [LocalizationKey.ProductsInMarketingTool]: "Products in this Marketing Tool",
    [LocalizationKey.ShowAll]: "Show all",
    [LocalizationKey.ShowLess]: "Show less",
    [LocalizationKey.BackToSearch]: "Back to search",
    [LocalizationKey.OpenInVimeo]: "Open in vimeo",

    /*  PRODUCT DATA  */
    [LocalizationKey.ProductData]: "产品参数",
    [LocalizationKey.ProductDataNotes]: "该页面为您提供所有威泰克影像解决方案产品的技术参数\n如果您希望生成自动流程和价格单以管理您的网店，这里有您需要的产品数据。\n为方便使用，该信息以两种格式的Excel文件提供(JSON和XML)。",
    [LocalizationKey.ProductDataJsonInfo]: "可替代XML的，极小的、可读的、格式化数据文件。主要应用于服务器和网站应用间的数据传输。\nSquarespace使用JSON存储和组织用CMS搭建的网站内容。",
    [LocalizationKey.ProductDataXmlInfo]: "是一种标记语言，定义了一组用于在一个人类可读和机器可读的格式编码的文件的规则。XML强调网络传输的简单性、通用性和实用性，通过Unicode文本数据以支持不同国家语",
    [LocalizationKey.ProductImages]: "产品图片 (zip文件)",

    /*  VISUAL PRICE LIST  */
    [LocalizationKey.VisualPriceList]: "价格单",
    [LocalizationKey.ProductCode]: "产品型号",
    [LocalizationKey.ProductDescription]: "产品描述",
    [LocalizationKey.ViewBy]: "查看方式",
    [LocalizationKey.Found]: "找到",
    [LocalizationKey.Product]: "个产品",
    [LocalizationKey.MasterCarton]: "装箱信息",
    [LocalizationKey.SuggestedPrice]: "建议零售价",
    [LocalizationKey.Loading]: "数据下载中，请稍后...",
    [LocalizationKey.TechnicalSpecifications]: "技术参数",
    [LocalizationKey.Specification]: "参数",
    [LocalizationKey.Value]: "值",
    [LocalizationKey.CountryOfOrigin]: "原产地",
}

export default zhCN;