import { Tooltip, Zoom, IconButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';

interface IProps {
    size?: 'medium' | 'small'
    link?: string
    onClick?: () => void
}

let EditIconButton = ({ size = 'small', link, onClick }: IProps) => {
    return (
        <>
            {link !== undefined && link !== null &&
                <Tooltip TransitionComponent={Zoom} title="Edit">
                    <IconButton
                        size={size}
                        color="primary"
                        component={Link}
                        to={link}
                    >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            }
            {(link === undefined || link === null) &&
                <Tooltip TransitionComponent={Zoom} title="Edit">
                    <IconButton
                        size={size}
                        color="primary"
                        onClick={ev => {
                            if (onClick)
                                onClick();
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            }
        </>
    )
}

export default EditIconButton;