import { Grid, TablePagination } from "@mui/material";
import { useContext } from "react";
import { adminUsersContext } from "../AdminUsersContext";

let Toolbar = () => {
    let service = useContext(adminUsersContext);

    return (
        <Grid container>
            <Grid item xs />

            <Grid item>
                <TablePagination
                    component="div"
                    count={service.recordCount}
                    onPageChange={service.handleChangePage}
                    page={service.search.pageNr}
                    rowsPerPage={service.search.pageSize}
                    rowsPerPageOptions={[service.search.pageSize]}
                    labelDisplayedRows={(props) => {
                        return (
                            <>
                                Total users:&nbsp;{service.recordCount} &nbsp;&middot;&nbsp;
                                <span>Page&nbsp;{props.page + 1}&nbsp;of&nbsp;{Math.ceil(props.count / service.search.pageSize)}</span>
                            </>
                        )
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default Toolbar;