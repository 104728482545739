import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';

import 'helpers/Extensions';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import theme from 'assets';

import { Routes, ScrollReset, Cookiebot } from './components';
import { AuthProvider, ApplicationProvider } from 'helpers/services';
import { ThemeProvider } from '@mui/material';
import { AxiosInterceptors } from 'helpers/services';


const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Cookiebot />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <SnackbarProvider preventDuplicate autoHideDuration={4000}>
          <BrowserRouter>
            <AuthProvider>
              <AxiosInterceptors />
              <ApplicationProvider>
                <ScrollReset />
                <Routes />
              </ApplicationProvider>
            </AuthProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;