import { useLocation } from 'react-router-dom';
import AdminUserDetails from './AdminUserDetails';
import AdminUserDetailsProvider from './AdminUserDetailsContext';

let Index = () => {
    let location = useLocation();

    let isNew = location.pathname.includes('/addnew');

    return (
        <AdminUserDetailsProvider isNew={isNew}>
            <AdminUserDetails isNew={isNew} />
        </AdminUserDetailsProvider>
    )
}

export default Index;