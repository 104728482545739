import { useContext } from 'react';
import { Route, Navigate, Routes as Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { AuthLayout } from 'components';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

import {
    Homepage,
    Error403,
    Error404,
    MarketingTools,
    MarketingTool,
    ProductData,
    VisualPriceList,
    ProductDetails,
    Cookies,
    DownloadRequest,
    Error401
} from 'views';

import {
    Users,
    UserDetails,
    AdminUsers,
    AdminUserDetails
} from 'views/Admin';

import {
    Login,
    ForgotPassword,
    ResetPassword
} from 'views/Account';

import Localizations from 'helpers/Localization';
import { applicationContext } from 'helpers/services';


let Routes = () => {
    let { currentUser } = useContext(applicationContext);

    return (
        <IntlProvider
            defaultLocale='en'
            locale={currentUser?.localeCode ?? 'en-GB'}
            messages={Localizations[currentUser?.localeCode ?? 'en-GB']}
        >
            <Router>
                <Route
                    path="/"
                    element={<PublicRoute />}
                >
                    <Route index element={<Homepage />} />
                    <Route path="cookies" element={<Cookies />} />

                    <Route
                        path="account"
                        element={<AuthLayout />}
                    >
                        <Route path="login" element={<Login />} />
                        <Route path="forgotpassword" element={<ForgotPassword />} />
                        <Route path="resetpassword" element={<ResetPassword />} />
                    </Route>

                    <Route path="account/changepassword" element={<Homepage />} />

                    <Route path="error403" element={<Error403 />} />
                </Route>

                <Route
                    path="/"
                    element={<PrivateRoute />}
                >
                    <Route path="home/marketingtools" element={<MarketingTools />} />
                    <Route path="home/marketingtools/detail/:id" element={<MarketingTool />} />
                    <Route path="home/productdata" element={<ProductData />} />
                    <Route path="home/visualpricelist" element={<VisualPriceList />} />
                    <Route path="home/productdetail" element={<ProductDetails />} />
                    <Route path="downloadrequest/:id" element={<DownloadRequest />} />
                </Route>

                <Route
                    path="admin"
                    element={<PrivateRoute />}
                >
                    <Route path="users" element={<Users />} />
                    <Route path="user/addnew/:id?" element={<UserDetails />} />
                    <Route path="user/edit/:id" element={<UserDetails />} />

                    <Route path="adminusers" element={<AdminUsers />} />
                    <Route path="adminuser/addnew" element={<AdminUserDetails />} />
                    <Route path="adminuser/edit/:id" element={<AdminUserDetails />} />
                </Route>

                <Route path={'/error401'} element={<Error401 />} />
                <Route path="*" element={<Error404 />} />
            </Router>
        </IntlProvider>
    );
}

export default Routes;