import { TableContainer, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Button, Chip } from "@mui/material";
import { DeleteIconButton, EditIconButton } from "components";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { adminUsersContext } from "../AdminUsersContext";
import { Check as CheckIcon } from '@mui/icons-material';

let Results = () => {
    let service = useContext(adminUsersContext);


    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell colSpan={2} align="right">
                            <Button component={Link} variant="outlined" size="small" to="/admin/adminuser/addnew">
                                Add new
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {service.users.map(user =>
                        <TableRow hover key={user.id}>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.firstName}&nbsp;{user.lastName}</TableCell>
                            <TableCell>
                                {user.isAdmin === true && <Chip label="Admin" color="success" variant="outlined" icon={<CheckIcon />} />}
                            </TableCell>
                            <TableCell align="right">
                                <EditIconButton size="small" link={`/admin/adminuser/edit/${user.id}`} />
                                <DeleteIconButton onClick={() => service.deleteUser(user.id)} />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
                {service.users.length === 0 &&
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={4}>
                                <i>No users has been found</i>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                }
            </Table>
        </TableContainer>
    )
}

export default Results;