import { SearchOutlined } from "@mui/icons-material";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { FilterContainer } from "components";
import { applicationContext } from "helpers/services";
import { useContext, useState } from "react";
import { usersContext } from "../UsersContext";

let Filters = () => {
    let appService = useContext(applicationContext);
    let service = useContext(usersContext);

    let [searchBrand, setSearchBrand] = useState('');
    let [searchMarket, setSearchMarket] = useState('');
    let [searchCountry, setSearchCountry] = useState('');


    return (
        <FilterContainer>
            <Grid item>
                <TextField
                    fullWidth
                    size='small'
                    variant="standard"
                    label="Search by name or email"
                    value={service.search.searchText}
                    onChange={(e: any) => service.updateSearch({
                        ...service.search,
                        searchText: e.target.value
                    })}
                />
            </Grid>
            <Grid item>
                <TextField
                    fullWidth
                    size='small'
                    variant="standard"
                    label="M3 Id"
                    value={service.search.extId}
                    onChange={(e: any) => service.updateSearch({
                        ...service.search,
                        extId: e.target.value
                    })}
                />
            </Grid>
            <Grid item xs={2}>
                <Autocomplete
                    size="small"
                    inputValue={searchCountry}
                    value={service.search.countryCode}
                    onInputChange={(event, newInputValue) => setSearchCountry(newInputValue)}
                    getOptionLabel={(option) => appService.countries.getValueByKey(option) || ''}
                    options={appService.countries.map(x => x.key)}
                    onChange={(e: any, value) => service.updateSearch({
                        ...service.search,
                        countryCode: value || ''
                    })}
                    renderInput={(params) => <TextField {...params} variant="standard" label="Country" fullWidth />}
                />
            </Grid>
            <Grid item xs={2}>
                <Autocomplete
                    multiple
                    size="small"
                    inputValue={searchBrand}
                    value={service.search.brands}
                    onInputChange={(event, newInputValue) => setSearchBrand(newInputValue)}
                    getOptionLabel={(option) => appService.availableBrands.getValueByKey(option) || ''}
                    options={appService.availableBrands.map(x => x.key)}
                    onChange={(e: any, value) => service.updateSearch({
                        ...service.search,
                        brands: value || ''
                    })}
                    renderInput={(params) => <TextField label="Brands" variant="standard" {...params} fullWidth />}
                />
            </Grid>
            <Grid item xs={2}>
                <Autocomplete
                    multiple
                    size="small"
                    inputValue={searchMarket}
                    value={service.search.markets}
                    onInputChange={(event, newInputValue) => setSearchMarket(newInputValue)}
                    getOptionLabel={(option) => appService.markets.getValueByKey(option) || ''}
                    options={appService.markets.map(x => x.key)}
                    onChange={(e: any, value) => service.updateSearch({
                        ...service.search,
                        markets: value || ''
                    })}
                    renderInput={(params) => <TextField label="Markets" variant="standard" {...params} fullWidth />}
                />
            </Grid>
            <Grid item>
                <Button type="submit" variant="contained" onClick={() => service.doSearch(service.search)}>
                    <SearchOutlined />
                </Button>
            </Grid>
        </FilterContainer>
    )
}

export default Filters;