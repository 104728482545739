import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

interface IVisualPriceListContext {
    isLoading: boolean
    recordCount: number
    search: ISearch
    products: IProduct[]
    viewType: 'table' | 'grid'

    updateSearch: (newSearch: ISearch) => void
    doSearch: (values: ISearch) => void
    searchVisualPriceList: (searchRequest: ISearch) => Promise<void>
    handleChangePage: (event: any, page: number) => void
    updateViewType: (newViewType: 'table' | 'grid') => void
}

export interface IProduct {
    brandCode: string
    currency: string
    description: string
    ean: string
    itemMCQuantity: string
    resourceId: string
    sku: string
    suggestedPrice: number
    suggestedPriceVAT: number
    upc: string
}

interface ISearch {
    searchText: string
    brand: string
    productCode: string
    productDescription: string
    enabledBrands: string[]

    pageNr: number
    pageSize: number
}

export let visualPriceListContext = React.createContext({} as IVisualPriceListContext);

let { Provider } = visualPriceListContext;

let VisualPriceListProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let location = useLocation();
    let navigate = useNavigate();

    let [isLoading, setLoading] = useState(false);
    let [search, setSearch] = useState<ISearch>({
        searchText: '',
        brand: '',
        productCode: '',
        productDescription: '',
        enabledBrands: [],
        pageNr: 1,
        pageSize: 20,

    });
    let [recordCount, setTotalRecords] = useState(0);
    let [products, setProducts] = useState<IProduct[]>([]);
    let [viewType, setViewType] = useState<'table' | 'grid'>('grid');

    useEffect(() => {
        if (location.state)
            processState(location.state);
    }, [location.state])

    if (!location.state)
        return <Navigate to={location.pathname} state={{ search }} replace={true} />

    let processState = (state: any) => {
        let request = { ...search }

        if (state.search) {
            request = {
                ...request,
                ...state.search
            }
        }

        setSearch(request);
        searchVisualPriceList(request);
    }

    let doSearch = (values: ISearch) => {
        let request = {
            ...values,
            pageNr: values.pageNr > 1 ? 1 : values.pageNr
        }

        navigate(location.pathname, { state: { search: request } });
    }

    let updateSearch = (newSearch: ISearch) => {
        setSearch(newSearch);
    }

    let updateViewType = (newViewType: 'table' | 'grid') => {
        setViewType(newViewType);
    }

    let searchVisualPriceList = async (searchRequest: ISearch) => {
        try {
            let request = {
                ...searchRequest
            }

            setLoading(true);
            let response = await axios.post('/api/VisualPriceList/SearchProducts', request);

            setProducts(response.data.items);
            setTotalRecords(response.data.recordCount);

        } catch (error) { }
        finally {
            setLoading(false);
        }
    }

    let handleChangePage = (event: any, page: number) => {
        let request = {
            ...search,
            pageNr: page
        }

        window.scrollTo(0, 0);
        navigate(location.pathname, { state: { search: request } });
    }


    return (
        <Provider
            value={{
                isLoading,
                recordCount,
                search,
                products,
                viewType,

                updateSearch,
                doSearch,
                searchVisualPriceList,
                handleChangePage,
                updateViewType
            }}
        >
            {children}
        </Provider>
    )
}

export default VisualPriceListProvider;