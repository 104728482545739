import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

interface IContext {
    isLoading: boolean
    recordCount: number
    search: ISearch
    users: IUser[]

    updateSearch: (newSearch: ISearch) => void
    doSearch: (values: ISearch) => void
    searchUsers: (searchRequest: ISearch) => Promise<void>
    handleChangePage: (event: any, page: number) => void
    deleteUser: (userId: string) => Promise<void>
}

interface IUser {
    id: string
    email: string
    firstName: string
    lastName: string
    isAdmin: boolean
}

interface ISearch {
    searchText: string
    pageNr: number
    pageSize: number
}

export let adminUsersContext = React.createContext({} as IContext);

let { Provider } = adminUsersContext;

let AdminUsersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let location = useLocation();
    let navigate = useNavigate();

    const defaultSearch: ISearch = {
        searchText: '',
        pageNr: 0,
        pageSize: 20
    }

    let [isLoading, setLoading] = useState(false);
    let [search, setSearch] = useState<ISearch>(defaultSearch);
    let [recordCount, setTotalRecords] = useState(0);
    let [users, setUsers] = useState<IUser[]>([]);


    useEffect(() => {
        if (location.state)
            processState(location.state);
    }, [location.state])


    if (!location.state)
        return <Navigate to={location.pathname} state={{ search }} />


    let processState = (state: any) => {
        let request = { ...search }

        if (state.search) {
            request = {
                ...request,
                ...state.search
            }
        }

        setSearch(request);
        searchUsers(request);
    }

    let doSearch = (values: ISearch) => {
        let request = {
            ...values,
            pageNr: 0
        }

        navigate(location.pathname, { state: { search: request } });
    }

    let updateSearch = (newSearch: ISearch) => {
        setSearch(newSearch);
    }

    let searchUsers = async (searchRequest: ISearch) => {
        try {
            setLoading(true);
            let response = await axios.post('/api/AdminUser/SearchUsers', searchRequest);

            setUsers(response.data.items);
            setTotalRecords(response.data.recordCount);

        } catch (error) { }
        finally {
            setLoading(false);
        }
    }

    let handleChangePage = (event: any, page: number) => {
        let request = {
            ...search,
            pageNr: page
        }

        window.scrollTo(0, 0);
        navigate(location.pathname, { state: { search: request } });
    }

    let deleteUser = async (userId: string) => {
        if (window.confirm("Do you want to delete this user?") === false)
            return;

        try {
            let res = await axios.delete(`/api/AdminUser/DeleteUser/${userId}`);
            if (res.data.hasDeleted === true)
                setUsers([...users.filter(x => x.id !== userId)]);

        } catch (err) {

        }
    }


    return (
        <Provider
            value={{
                isLoading,
                recordCount,
                search,
                users,

                updateSearch,
                doSearch,
                searchUsers,
                handleChangePage,
                deleteUser
            }}
        >
            {children}
        </Provider>
    )
}

export default AdminUsersProvider;