enum LocalizationKey {
    /*  ERROR 403  */
    Error403 = "Error403",
    Error403Note = "Error403Note",

    /*  ERROR 404  */
    Error404 = "Error404",
    Error404Note = "Error404Note",

    /*  GENERAL  */
    Hi = "Hi",
    Logout = "Logout",
    PrivacyLink = "PrivacyLink",
    DownloadError = "DownloadError",
    DownloadSuccess = "DownloadSuccess",
    DownloadInProgress = "DownloadInProgress",
    RequestSent = "RequestSent",
    OurBrands = "OurBrands",
    NoResultsFound = "NoResultsFound",
    Markets = "Markets",
    Brand = "Brand",
    Brands = "Brands",
    ViewMore = "ViewMore",
    Products = "Products",
    DownloadAllImages = "DownloadAllImages",
    DownloadAllImagesNotes = "DownloadAllImagesNotes",
    DownloadSelectedImages = "DownloadSelectedImages",
    Download = "Download",
    ProductDetail = "ProductDetail",
    PhoneNumber = "PhoneNumber",
    MarketingTools = "MarketingTools",
    Search = "Search",
    Any = "Any",
    Previous = "Previous",
    Next = "Next",
    Close = "Close",
    Confirm = "Confirm",
    Page = "Page",
    Of = "Of",

    /*  HOMEPAGE  */
    HomepageWelcomeTo = "HomepageWelcomeTo",
    HomepageWelcomeAnonymus = "HomepageWelcomeAnonymus",
    HomepageWelcomeAuthenticated = "HomepageWelcomeAuthenticated",
    HomepageAboutUs = "HomepageAboutUs",
    HomepageAboutUsLine1 = "HomepageAboutUsLine1",
    HomepageAboutUsLine2 = "HomepageAboutUsLine2",
    Videndum = "Videndum",
    ProductInformation = "ProductInformation",
    HomepageWelcomeMarketingTools = "HomepageWelcomeMarketingTools",

    /*  MARKETING TOOLS  */
    HomepageMarketingTools = "HomepageMarketingTools",
    Filters = "Filters",
    SearchByTitle = "SearchByTitle",
    Category = "Category",
    Year = "Year",
    MarketingToolsFound = "MarketingToolsFound",
    ProductsInMarketingTool = "ProductsInMarketingTool",
    ShowAll = "ShowAll",
    ShowLess = "ShowLess",
    BackToSearch = "BackToSearch",
    OpenInVimeo = "OpenInVimeo",

    /*  PRODUCT DATA  */
    ProductData = "ProductData",
    ProductDataNotes = "ProductDataNotes",
    ProductDataJsonInfo = "ProductDataJsonInfo",
    ProductDataXmlInfo = "ProductDataXmlInfo",
    ProductImages = "ProductImages",

    /*  VISUAL PRICE LIST  */
    VisualPriceList = "VisualPriceList",
    ProductCode = "ProductCode",
    ProductDescription = "ProductDescription",
    ViewBy = "ViewBy",
    Found = "Found",
    Product = "Product",
    MasterCarton = "MasterCarton",
    SuggestedPrice = "SuggestedPrice",
    Loading = "Loading",
    TechnicalSpecifications = "TechnicalSpecifications",
    Specification = "Specification",
    Value = "Value",
    CountryOfOrigin = "CountryOfOrigin",
}

export default LocalizationKey;