import { LocalizationKey } from "helpers/Enums";

let frFR = {
    /*  ERROR 403  */
    [LocalizationKey.Error403]: "Quelque chose ne va pas avec votre utilisateur",
    [LocalizationKey.Error403Note]: "Essayez de vous déconnecter et de vous reconnecter: si rien ne change, demandez l'assistance technique.",

    /*  ERROR 404  */
    [LocalizationKey.Error404]: "404: Page non découverte",
    [LocalizationKey.Error404Note]: "Vous visitez certainement une ancienne page. Veuillez vérifier l'URL ou allez sur la {homeLink}",

    /*  GENERAL  */
    [LocalizationKey.Hi]: "Salut",
    [LocalizationKey.Logout]: "Logout",
    [LocalizationKey.PrivacyLink]: "https://www.videndummediasolutions.com/fr/politique-de-confidentialite/",
    [LocalizationKey.DownloadError]: "An error occurred while processing your request. Refresh this page to try again",
    [LocalizationKey.DownloadSuccess]: "Your request has been processed successfully",
    [LocalizationKey.DownloadInProgress]: "Your request is in progress",
    [LocalizationKey.RequestSent]: "Request sent",
    [LocalizationKey.OurBrands]: "Our brands",
    [LocalizationKey.NoResultsFound]: "Aucun résultat trouvé",
    [LocalizationKey.Markets]: "Markets",
    [LocalizationKey.Brand]: "Marque",
    [LocalizationKey.Brands]: "Brands",
    [LocalizationKey.ViewMore]: "Voir plus",
    [LocalizationKey.Products]: "produit",
    [LocalizationKey.DownloadAllImages]: "Télécharger toutes les images",
    [LocalizationKey.DownloadAllImagesNotes]: "Nous enverrons un fichier ZIP à votre adresse e-mail {emailAddress}",
    [LocalizationKey.Download]: "Download",
    [LocalizationKey.ProductDetail]: "Product detail",
    [LocalizationKey.PhoneNumber]: "+39 0424 555855",
    [LocalizationKey.MarketingTools]: "Supports Marketing",
    [LocalizationKey.Search]: "Rechercher",
    [LocalizationKey.Any]: "Any",
    [LocalizationKey.Previous]: "Précédent",
    [LocalizationKey.Next]: "Suivant",
    [LocalizationKey.Close]: "Fermer",
    [LocalizationKey.Confirm]: "Confirmer",
    [LocalizationKey.Page]: "Page",
    [LocalizationKey.Of]: "of",

    /*  HOMEPAGE  */
    [LocalizationKey.HomepageWelcomeTo]: " BIENVENUE CHEZ VIDENDUM MEDIA DISTRIBUTION",
    [LocalizationKey.HomepageWelcomeAnonymus]: " Bienvenue dans {videndumBold}, un espace dÃ©diÃ© aux Distributeurs officiels.\nConnectez-vous pour accéder à toutes les {productInfoBold} et aux {marketingToolsBold} utiles pour vendre efficacement nos produits, et les dernières informations concernant Videndum Media Solutions.",
    [LocalizationKey.HomepageWelcomeAuthenticated]: "Pour connaître la plupart des ressources et services disponibles, veuillez lire le {link}\nNous espérons que vous trouverez ce site utile.",
    [LocalizationKey.HomepageAboutUs]: "A PROPOS",
    [LocalizationKey.HomepageAboutUsLine1]: " Videndum Media Solutions conçoit, fabrique et distribue des marques haut de gamme d'équipements photo et vidéo comme des trépieds, bagagerie, filtres, éclairages pour des professionnels et des clients photographes et vidéastes.",
    [LocalizationKey.HomepageAboutUsLine2]: "Les produits Videndum Media Solutions sont distribués par Manfrotto Distribution, en Allemagne, Benelux, Chine, Etats-Unis, France, Hong Kong, Italie, Japon et Royaume-Uni, et auprés d'un réseau de distributeurs indépendants dans plus de 65 pays dans le monde. La force inégalée du réseau de distribution international et les efforts d'équipe entre la société et les distributeurs est un élément clé dans la réussite.",
    [LocalizationKey.Videndum]: "Videndum Media Distributors Area",
    [LocalizationKey.ProductInformation]: "informations produits",
    [LocalizationKey.HomepageWelcomeMarketingTools]: "supports marketing",

    /*  MARKETING TOOLS  */
    [LocalizationKey.HomepageMarketingTools]: "Supports Marketing",
    [LocalizationKey.Filters]: "Filters",
    [LocalizationKey.SearchByTitle]: "Recherche par titre ou sku...",
    [LocalizationKey.Category]: "Category",
    [LocalizationKey.Year]: "Year",
    [LocalizationKey.MarketingToolsFound]: "Marketing tools found",
    [LocalizationKey.ProductsInMarketingTool]: "Products in this Marketing Tool",
    [LocalizationKey.ShowAll]: "Show all",
    [LocalizationKey.ShowLess]: "Show less",
    [LocalizationKey.BackToSearch]: "Back to search",
    [LocalizationKey.OpenInVimeo]: "Open in vimeo",

    /*  PRODUCT DATA  */
    [LocalizationKey.ProductData]: "Données produits",
    [LocalizationKey.ProductDataNotes]: "Vous trouverez ici toutes les informations techniques des produits des marques de Videndum Media Solutions.\nSi vous souhaitez ensuite ouvrir votre site e-commerce et générer tous les contenus sur votre site, vous trouverez toutes les ressources sur ce site.\nToutes les informations sont disponibles sous Excel sous 2 formats (JSON et XML).",
    [LocalizationKey.ProductDataJsonInfo]: "Il s'agit d'informations sous un format concis, lisible et structuré. Il est généralement utilisé comme une alternative au format XML pour transmettre des données entre une application web et le serveur.\nSquarespace use le format JSON pour stocker et le contenu du site créé avec le CMS.",
    [LocalizationKey.ProductDataXmlInfo]: "Il s'agit d'un langage à balises qui code le document de manière à être compris par l'homme et la machine. Le XML définit un langage simple et générique. C'est un format de données textuelles avec un appui Unicode pour les différentes langues humaines.",
    [LocalizationKey.ProductImages]: "Images produits (zip)",

    /*  VISUAL PRICE LIST  */
    [LocalizationKey.VisualPriceList]: "Liste des tarifs",
    [LocalizationKey.ProductCode]: "Code produit",
    [LocalizationKey.ProductDescription]: "Description produit",
    [LocalizationKey.ViewBy]: "Filtrer par",
    [LocalizationKey.Found]: "Trouvé",
    [LocalizationKey.Product]: "Product",
    [LocalizationKey.MasterCarton]: "Master carton",
    [LocalizationKey.SuggestedPrice]: "Prix de vente conseillé",
    [LocalizationKey.Loading]: "Chargement...",
    [LocalizationKey.TechnicalSpecifications]: "Technical specifications",
    [LocalizationKey.Specification]: "Spécifications",
    [LocalizationKey.Value]: " Valeur",
    [LocalizationKey.CountryOfOrigin]: "Pays d'origine",
}

export default frFR;