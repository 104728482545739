import { useIntl } from 'react-intl';
import { HomeBrands } from 'components';
import { useContext } from 'react';
import { authContext } from 'helpers/services';
import { LocalizationKey } from 'helpers/Enums';
import { Button, Link } from '@mui/material';

let Homepage = () => {
    let { formatMessage } = useIntl();

    let { isAuthenticated, openLogin } = useContext(authContext);


    return (
        <>
            <div className="container-fluid special-p">
                <div className="home-msg">
                    <h1>{formatMessage({ id: LocalizationKey.HomepageWelcomeTo })}</h1>

                    <p className="lead" style={{ marginTop: 32, marginBottom: 32 }}>
                        {isAuthenticated === true
                            ? formatMessage(
                                { id: LocalizationKey.HomepageWelcomeAuthenticated },
                                {
                                    videndumBold: <b>{formatMessage({ id: LocalizationKey.Videndum })}</b>,
                                    link: <a href="/Content/distributors_user_guide.pdf"> quick start guide </a>
                                })
                            : formatMessage(
                                { id: LocalizationKey.HomepageWelcomeAnonymus },
                                {
                                    videndumBold: <b>{formatMessage({ id: LocalizationKey.Videndum })}</b>,
                                    productInfoBold: <b>{formatMessage({ id: LocalizationKey.ProductInformation })}</b>,
                                    marketingToolsBold: <b>{formatMessage({ id: LocalizationKey.HomepageWelcomeMarketingTools })}</b>
                                })
                        }
                    </p>
                    <div className="vitec-brands pb-3">
                        <HomeBrands />
                    </div>
                    {isAuthenticated === false &&
                        <Button
                            component={Link}
                            variant='contained'
                            style={{ width: 350 }}
                            onClick={openLogin}
                        >
                            Login
                        </Button>
                    }
                </div>
            </div>
            <div className="mt-5 pt-5 pb-5 container-fluid special-p gray-bg">
                <div className="container-fluid">
                    <div>
                        <h1>{formatMessage({ id: LocalizationKey.HomepageAboutUs })}</h1>
                        <div className="row">
                            <p className=" col-md-12">
                                {formatMessage({ id: LocalizationKey.HomepageAboutUsLine1 })}
                                <br />
                                {formatMessage({ id: LocalizationKey.HomepageAboutUsLine2 })}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Homepage;