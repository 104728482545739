import { Grid } from "@mui/material";
import { Login } from "components";
import { useLocation } from "react-router-dom";

const LoginView = () => {
    const location = useLocation();

    const params = new URLSearchParams(location.search);

    const returnUrl = params.get('returnUrl');

    return (
        <Grid container sx={{ mt: 8 }}>
            <Grid item xs={10}>
                <Login returnUrl={returnUrl as string} />
            </Grid>
        </Grid>
    )
}

export default LoginView;