import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

interface IMarketingToolsContext {
    isLoading: boolean
    recordCount: number
    search: ISearch
    marketingTools: IMarketingTool[]

    updateSearch: (newSearch: ISearch) => void
    doSearch: (values: ISearch) => void
    searchMarketingTools: (searchRequest: ISearch) => Promise<void>
    handleChangePage: (page: number) => void
}

export interface IMarketingTool {
    marketingToolId: string
    brand: string
    title: string
    extension: string
    date: moment.Moment
    IsMedia: boolean
    mainMediaId: string
}

interface ISearch {
    brands: string[]
    types: string[]
    markets: string[]
    years: number[]
    text: string
    sortBy: string

    pageNr: number
    pageSize: number
}

export let marketingToolsContext = React.createContext({} as IMarketingToolsContext);

let { Provider } = marketingToolsContext;

let MarketingToolsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let location = useLocation();
    let navigate = useNavigate();

    let [isLoading, setLoading] = useState(false);
    let [search, setSearch] = useState<ISearch>({
        markets: [],
        brands: [],
        pageNr: 1,
        pageSize: 20,
        text: '',
        sortBy: '',
        types: [],
        years: []
    });
    let [recordCount, setTotalRecords] = useState(0);
    let [marketingTools, setMarketingTools] = useState<IMarketingTool[]>([]);

    useEffect(() => {
        if (location.state)
            processState(location.state);
    }, [location.state])

    if (!location.state)
        return <Navigate to={location.pathname} state={{ search }} />

    let processState = (state: any) => {
        let request = { ...search }

        if (state.search) {
            request = {
                ...request,
                ...state.search
            }
        }

        setSearch(request);
        searchMarketingTools(request);
    }

    let doSearch = (values: ISearch) => {
        let request = {
            ...values,
            pageNr: values.pageNr > 1 ? 1 : values.pageNr
        }

        navigate(location.pathname, { state: { search: request } });
    }

    let updateSearch = (newSearch: ISearch) => {
        setSearch(newSearch);
    }

    let searchMarketingTools = async (searchRequest: ISearch) => {
        try {
            setLoading(true);

            let response = await axios.post('/api/MarketingTool/SearchMarketingTools', searchRequest);

            setMarketingTools(response.data.items);
            setTotalRecords(response.data.recordCount);

        } catch (error) { }
        finally {
            setLoading(false);
        }
    }

    let handleChangePage = (page: number) => {
        let request = {
            ...search,
            pageNr: page
        }

        window.scrollTo(0, 0);
        navigate(location.pathname, { state: { search: request } });
    }

    return (
        <Provider
            value={{
                isLoading,
                recordCount,
                search,
                marketingTools,

                updateSearch,
                doSearch,
                searchMarketingTools,
                handleChangePage,
            }}
        >
            {children}
        </Provider>
    )
}

export default MarketingToolsProvider;