import React from 'react';
import MarketingTool from './MarketingTool';
import MarketingToolProvider from './MarketingToolContext';


let Index = () => {
    return (
        <MarketingToolProvider>
            <MarketingTool />
        </MarketingToolProvider>
    )
}

export default Index;