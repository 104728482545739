import { useContext, useState } from 'react';
import { Button, Typography, Grid, CircularProgress } from '@mui/material';
import { LockOpenOutlined as UnLockIcon } from '@mui/icons-material';
import { authContext } from 'helpers/services';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { TextField } from 'formik-mui';
import { useLocation } from 'react-router-dom';


let ChangePassword = () => {
    let location = useLocation();

    let authService = useContext(authContext);

    let [isLoading, setLoading] = useState(false);

    let changePasswordSchema = yup.object({
        clientId: yup.string().required("ClientId not set").default(''),
        clientSecret: yup.string().required("Secret not set").default(''),
        newPassword: yup.string().required("New password is mandatory").default(''),
        newPasswordConfirm: yup.string()
            .oneOf([yup.ref('newPassword'), null], "Passwords does not match")
            .required("Confirm new password")
            .default('')
    })


    return (
        <Grid container alignItems='center'>
            <Grid item xs={12}>
                <Typography variant="h5" textAlign='center'>Password change</Typography>
                <Typography textAlign='center'>In order to login please choose a new password</Typography>
            </Grid>
            <Grid item xs>
                <Formik
                    initialValues={{
                        ...changePasswordSchema.getDefault(),
                        clientId: location.state?.clientId || '',
                        clientSecret: location.state?.clientSecret || ''
                    }}
                    validationSchema={changePasswordSchema}
                    onSubmit={async (values, action) => {
                        setLoading(true);
                        let res = await authService.signInAndChangePassword(values.clientId, values.clientSecret, values.newPassword);
                        if (res === false) {
                            action.setSubmitting(false);
                            setLoading(false);
                        }
                    }}
                >
                    <Form>
                        <Grid container alignItems="center">
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    name="newPassword"
                                    label="New password"
                                    variant="outlined"
                                    fullWidth
                                    type="password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    name="newPasswordConfirm"
                                    label="New password confirm"
                                    variant="outlined"
                                    fullWidth
                                    type="password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    style={{ width: '100%' }}
                                    startIcon={isLoading ? <CircularProgress size={20} /> : <UnLockIcon />}
                                >
                                    Change password
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </Grid>
        </Grid>
    )
}

export default ChangePassword;