import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import axios from 'axios';
import { LocalizationKey } from 'helpers/Enums';
import { applicationContext } from 'helpers/services';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';

interface IEdtiTrackingNumberProps {
    component: "button" | "li" | 'a'
    linkedTo: 'MarketingTool' | 'Product'
    id: string
}

let DownloadAll = (props: IEdtiTrackingNumberProps) => {
    let { formatMessage } = useIntl();
    let { enqueueSnackbar } = useSnackbar();

    let appService = useContext(applicationContext);

    let [isOpen, setOpen] = useState(false);


    let downloadImages = async () => {
        try {
            let values = {
                ids: [props.id] as string[],
                linkedTo: props.linkedTo
            }

            await axios.post('/api/DownloadRequest/SaveDownloadRequest', values);
            enqueueSnackbar(formatMessage({ id: LocalizationKey.RequestSent }), { variant: "success" });
            setOpen(false);

        } catch (err) { }
    }


    return (
        <>
            {props.component === "li" &&
                <li className="addtocart">
                    <div className="pictureBig text-center" onClick={(e) => setOpen(true)}>
                        <h3>
                            <span>{formatMessage({ id: LocalizationKey.DownloadAllImages })}</span>
                        </h3>
                    </div>
                </li>
            }

            {props.component === "button" &&
                <button type="button" className="btn btn-primary" onClick={(e) => setOpen(true)}>
                    <i className="fa fa-download" /> {formatMessage({ id: LocalizationKey.DownloadAllImages })}
                </button>
            }

            {props.component === "a" &&
                <a onClick={(e) => setOpen(true)}>
                    <i className="fa fa-download" /> {formatMessage({ id: LocalizationKey.DownloadAllImages })}
                </a>
            }

            <Dialog
                open={isOpen}
                maxWidth="md"
                fullWidth
                onClose={(e, reason) => {
                    if (reason === "escapeKeyDown")
                        setOpen(false)
                }}
            >
                <DialogTitle>{formatMessage({ id: LocalizationKey.DownloadAllImages })}</DialogTitle>

                <DialogContent>
                    <Typography variant="subtitle1">
                        {formatMessage(
                            { id: LocalizationKey.DownloadAllImagesNotes },
                            { emailAddress: <strong>{appService.currentUser?.email.toLowerCase()}</strong> })}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='outlined'
                        color="secondary"
                        onClick={() => setOpen(false)}
                    >
                        {formatMessage({ id: LocalizationKey.Close })}
                    </Button>
                    <Button
                        onClick={() => downloadImages()}
                        variant="contained"
                        color="primary"
                    >
                        {formatMessage({ id: LocalizationKey.Confirm })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DownloadAll;
