import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

interface IContext {
    isLoading: boolean
    adminUser: AdminUser

    saveAdminUser: (values: AdminUser) => Promise<void>
}

export let adminUserSchema = yup.object({
    email: yup.string().default('').required('Email is mandatory field'),
    firstName: yup.string().default('').required('Firstname is mandatory field'),
    lastName: yup.string().default('').required('Lastname is mandatory field'),
    isAdmin: yup.boolean().default(false),
    canManageUsers: yup.boolean().default(true)
});

export type AdminUser = yup.InferType<typeof adminUserSchema>;

export let adminUserContext = React.createContext({} as IContext);

let { Provider } = adminUserContext;

let AdminUserDetailsProvider: React.FC<{ isNew: boolean, children: React.ReactNode }> = ({ isNew, children }) => {
    let { id } = useParams<{ id: string }>();
    let navigate = useNavigate();

    let [isLoading, setLoading] = useState(isNew === false);
    let [adminUser, setAdminUser] = useState<AdminUser>(adminUserSchema.getDefault());


    useEffect(() => {
        if (isNew === false)
            getUser();
    }, [id, isNew])


    let getUser = async () => {
        try {
            let res = await axios.get('/api/AdminUser/GetUser', { params: { id } });

            let { item } = res.data;

            setAdminUser({
                ...adminUserSchema.getDefault(),
                ...item
            });
        } catch (err) { }
        finally {
            setLoading(false);
        }
    }

    let saveAdminUser = async (values: AdminUser) => {
        try {
            await axios.post('/api/AdminUser/SaveUser', values);
            navigate('/admin/adminusers');
        } catch (err) {
        }
    }


    return (
        <Provider
            value={{
                isLoading,
                adminUser,

                saveAdminUser
            }}
        >
            {children}
        </Provider>
    )
}

export default AdminUserDetailsProvider;