import { Page } from "components"
import { Filters, Results } from "./components";
import { useIntl } from "react-intl";
import { LocalizationKey } from "helpers/Enums";


let MarketingTools = () => {
    let { formatMessage } = useIntl();

    return (
        <Page title={formatMessage({ id: LocalizationKey.HomepageMarketingTools })}>
            <Filters />
            <Results />
        </Page>
    );

}

export default MarketingTools;