import { useContext, useState } from "react";
import { productDataContext } from "../ProductDataContext";
import { useIntl } from "react-intl";
import { LocalizationKey } from "helpers/Enums";

let Header = () => {
    let { formatMessage } = useIntl();
    
    let service = useContext(productDataContext);
    
    let [currentOver, setCurrentOver] = useState('');


    return (
        <>
            <h1 className="title-big text-center pt-5 pb-5">
                <span>{formatMessage({ id: LocalizationKey.ProductData })}</span>
            </h1>

            <hr />
            <div className="row">
                <div className="col-md-12">
                    <p className="lead text-center">{formatMessage({ id: LocalizationKey.ProductDataNotes })}</p>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-sm-6">
                    <blockquote>
                        <p><strong>JSON</strong> - JavaScript Object Notation</p>
                        <span className="small">{formatMessage({ id: LocalizationKey.ProductDataJsonInfo })}</span>
                    </blockquote>
                </div>
                <div className="col-xs-12 col-sm-6">
                    <blockquote>
                        <p><strong>XML</strong> - Extensible Markup Language</p>
                        <span className="small">{formatMessage({ id: LocalizationKey.ProductDataXmlInfo })} </span>
                    </blockquote>
                </div>
            </div>

            <hr />

            <div className="row userInfo">
                <div className="col-xs-12 col-sm-12">
                    <div className="w100 clearfix text-center">
                        <ul className="row no-gutters orderStepLook2" style={{ width: 'auto' }}>
                            {service.languages.map(language =>
                                <li key={language.isocode} role="presentation" className={service.selectedLanguage === language.isocode ? "col active" : "col"}>
                                    <a
                                        style={{ cursor: 'pointer', textDecoration: currentOver === language.isocode ? 'underline' : 'none' }}
                                        onClick={(e) => service.updateSelectedLanguage(language.isocode)}
                                        onMouseEnter={() => setCurrentOver(language.isocode)}
                                        onMouseLeave={() => setCurrentOver('')}
                                    >
                                        <img src={`/Content/img/flag/${language.flag}.png`} />
                                        <span>{language.isocode}</span>
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );

}

export default Header;