import { applicationContext } from 'helpers/services';
import { useContext } from 'react';
import Footer from './Footer';
import { Navbar, Topbar } from 'components/Menu';
import { UserDrawer } from 'components';


interface IProps {
    children: React.ReactNode
}

let Layout = ({ children }: IProps) => {
    let appService = useContext(applicationContext);


    return (
        <>
            <UserDrawer />
            <div className="pace  pace-inactive">
                <div className="pace-progress" data-progress-text="100%" data-progress="99" style={{ width: '100%' }}>
                    <div className="pace-progress-inner"></div>
                </div>
                <div className="pace-activity"></div>
            </div>
            <div id="mainBinding">
                <Topbar />
                <div className="moove">
                    <Navbar />
                    <div className="main-container headerOffset globalPaddingBottom">
                        {appService.isLoading === false &&
                            <>{children}</>
                        }
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default Layout;