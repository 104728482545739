import { useContext } from "react";
import { marketingToolsContext } from "../MarketingToolsContext";
import MarketingToolElement from "./MarketingToolElement";
import Pagination from "helpers/Pagination";
import { useIntl } from "react-intl";
import { LocalizationKey } from "helpers/Enums";
import { Loading } from "components";

let Results = () => {
    let service = useContext(marketingToolsContext);
    let { formatMessage } = useIntl();

    return (
        <div className="container-fluid special-p">
            {service.isLoading === true && <Loading />}
            {service.isLoading === false &&
                <div className="catColumnWrapper">
                    <div className="col-sm-12 filterColumn" />
                    <div className="row">
                        <div className="col-sm-12 categoryColumn">
                            {service.marketingTools.length !== 0 &&
                                <>
                                    <div className="row no-gutters categoryProduct xsResponse clearfix">
                                        {service.marketingTools.map(marketingTool =>
                                            <div className="item col-sm-4 col-md-3 col-xxl-2 col-xs-6" key={marketingTool.marketingToolId}>
                                                <MarketingToolElement marketingTool={marketingTool} />
                                            </div>
                                        )}
                                    </div>
                                    <div className="row categoryFooter">
                                        <div className="col-6">
                                            <Pagination
                                                numberOfItems={service.recordCount}
                                                pageNr={service.search.pageNr}
                                                onPageChange={(pageNr: number) => service.handleChangePage(pageNr)}
                                            />
                                        </div>
                                        <div className="col-6 pb-3 text-right">
                                            <span className="badge badge-dark">{service.recordCount} {formatMessage({ id: LocalizationKey.MarketingToolsFound })}</span>
                                        </div>
                                    </div>
                                </>
                            }
                            {service.marketingTools.length <= 0 &&
                                <h3 className="no-data">{formatMessage({ id: LocalizationKey.NoResultsFound })}</h3>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    );

}

export default Results;