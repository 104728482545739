import DownloadRequest from "./DownloadRequest";
import DownloadRequestProvider from "./DownloadRequestProvider";

let Index = () => {
    return (
        <DownloadRequestProvider>
            <DownloadRequest />
        </DownloadRequestProvider>
    )
}

export default Index;