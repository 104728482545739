import { Typography, Container } from '@mui/material';
import { useIntl } from 'react-intl';
import { LocalizationKey } from 'helpers/Enums';


let Error403 = () => {
    let { formatMessage } = useIntl();

    return (
        <Container maxWidth="lg">
            <Typography align="center" variant="h2" sx={{ mt: 8 }}>
                {formatMessage({ id: LocalizationKey.Error403 })}
            </Typography>
            <Typography align="center" variant="subtitle2" sx={{ mb: 8 }}>
                {formatMessage({ id: LocalizationKey.Error403Note })}
            </Typography>
        </Container>
    )
}

export default Error403;