import { useContext } from "react";
import { productDetailsContext } from "./ProductDetailsContext";
import { LeftColumn, RightColumn } from "./components";
import { applicationContext } from "helpers/services";
import { Loading } from "components";


let ProductDetails = () => {
    let { brands } = useContext(applicationContext);
    let service = useContext(productDetailsContext);

    return (
        <div className="container-fluid special-p ProductDetail">
            {service.isLoading === true && <Loading />}
            {service.isLoading === false &&
                <div>
                    <div className="details__header pt-5">
                        <div className={`brand-sign c-${service.productDetails.brand.toLowerCase()}`}>
                            <div className={`logo logo-${service.productDetails.brand.toLowerCase()}-lg-w`}>
                                <span></span>
                            </div>
                        </div>
                        <div className="details__header__container">
                            <h4 className="product-code">
                                SKU: <span>{service.productDetails.itemNumber}</span> · <span>{brands.getValueByKey(service.productDetails.brand, "key", "description")}</span>
                            </h4>
                            <h1 className="product-title">{service.productDetails.shortDescriptions}</h1>

                        </div>
                    </div>
                    <div className="product__container pt-5">
                        <div className="row">
                            <div className="col-xxl-5 col-lg-6">
                                <LeftColumn />
                            </div>
                            <div className="col-xxl-7 col-lg-6">
                                <RightColumn />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default ProductDetails;