import { LocalizationKey } from "helpers/Enums";

let jaJP = {
    /*  ERROR 403  */
    [LocalizationKey.Error403]: "Something is wrong with your user",
    [LocalizationKey.Error403Note]: "Try to logout and login again: if nothing change ask for technical support.",

    /*  ERROR 404  */
    [LocalizationKey.Error404]: "404: The page you are looking for isn’t here",
    [LocalizationKey.Error404Note]: "You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation",

    /*  GENERAL  */
    [LocalizationKey.Hi]: "ようこそ",
    [LocalizationKey.Logout]: "Logout",
    [LocalizationKey.PrivacyLink]: "https://www.videndummediasolutions.com/en/privacy-policy/",
    [LocalizationKey.DownloadError]: "An error occurred while processing your request. Refresh this page to try again",
    [LocalizationKey.DownloadSuccess]: "Your request has been processed successfully",
    [LocalizationKey.DownloadInProgress]: "Your request is in progress",
    [LocalizationKey.RequestSent]: "Request sent",
    [LocalizationKey.OurBrands]: "Our brands",
    [LocalizationKey.NoResultsFound]: "No results found",
    [LocalizationKey.Markets]: "Markets",
    [LocalizationKey.Brand]: "Brand",
    [LocalizationKey.Brands]: "Brands",
    [LocalizationKey.ViewMore]: "View more",
    [LocalizationKey.Products]: "Products",
    [LocalizationKey.DownloadAllImages]: "Download all images",
    [LocalizationKey.DownloadAllImagesNotes]: "We'll send a ZIP file to your email address {emailAddress}",
    [LocalizationKey.DownloadSelectedImages]: "Download selected images",
    [LocalizationKey.Download]: "Download",
    [LocalizationKey.ProductDetail]: "Product detail",
    [LocalizationKey.PhoneNumber]: "+39 0424 555855",
    [LocalizationKey.MarketingTools]: "マーケティングツール",
    [LocalizationKey.Search]: "検索",
    [LocalizationKey.Any]: "任意",
    [LocalizationKey.Previous]: "前",
    [LocalizationKey.Next]: "先",
    [LocalizationKey.Close]: "Close",
    [LocalizationKey.Confirm]: "Confirm",
    [LocalizationKey.Page]: "Page",
    [LocalizationKey.Of]: "of",

    /*  HOMEPAGE  */
    [LocalizationKey.HomepageWelcomeTo]: "ヴァイテックイメージング株式会社の サイトへようこそ",
    [LocalizationKey.HomepageWelcomeAnonymus]: "<b>ヴァイテックイメージング株式会社 ディストリビューター専用サイト</b>へようこそ。<br>ログインして頂き、<b>製品情報</b>や<b>マーケティング用の資料</b>をアクセスして頂けます。",
    [LocalizationKey.HomepageWelcomeAnonymus]: "Welcome to the {videndumBold}, a dedicated space to our official Distributors network.\nLog in to get access to all the {productInfoBold} and {marketingToolsBold} needed to sell effectively our products, and all the latest news from Manfrotto world.",
    [LocalizationKey.HomepageWelcomeAuthenticated]: "マンフロットが提供するリソースやサービスを最大限に活用するには {link}\nをご覧ください。 当サイトがお役に立てますことを心より願っております。",
    [LocalizationKey.HomepageAboutUs]: "当社について",
    [LocalizationKey.HomepageAboutUsLine1]: "ヴァイテックイメージング株式会社は、写真、映画、演劇、ライブエンターテインメント、ビデオのプロマーケット用の様々なサポート製品をデザイン、製造、 販売しています。当ブランドは、カメラ用三脚/雲台、ライティング用スタンドやアクセサリーなど広範囲に渡る製品ラインがあります。",
    [LocalizationKey.HomepageAboutUsLine2]: "ヴァイテックイメージング株式会社製品は全世界に65の独立販売人に加え、アメリカ、イギリス、イタリア、ドイツ、フランス、ベネルクス、香港、中国、日本の支社が取り扱っています。",
    [LocalizationKey.Videndum]: "ヴァイテックイメージング株式会社 ディストリビューター専用サイト",
    [LocalizationKey.ProductInformation]: "製品情報",
    [LocalizationKey.HomepageWelcomeMarketingTools]: "マーケティング用の資料",

    /*  MARKETING TOOLS  */
    [LocalizationKey.HomepageMarketingTools]: "Marketing Tools",
    [LocalizationKey.Filters]: "Filters",
    [LocalizationKey.SearchByTitle]: "Search by title or sku...",
    [LocalizationKey.Category]: "Category",
    [LocalizationKey.Year]: "Year",
    [LocalizationKey.MarketingToolsFound]: "Marketing tools found",
    [LocalizationKey.ProductsInMarketingTool]: "Products in this Marketing Tool",
    [LocalizationKey.ShowAll]: "Show all",
    [LocalizationKey.ShowLess]: "Show less",
    [LocalizationKey.BackToSearch]: "Back to search",
    [LocalizationKey.OpenInVimeo]: "Open in vimeo",

    /*  PRODUCT DATA  */
    [LocalizationKey.ProductData]: "プロダクトデータ",
    [LocalizationKey.ProductDataNotes]: "本サイトはヴァイテックイメージング株式会社 が取り扱う製品の仕様データや製品情報等をダウンロードすることができる、販売店専用のサイトです。\nJSON・XML形式でデータを提供します。",
    [LocalizationKey.ProductDataJsonInfo]: "最小限の読み取り可能なフォーマットです。 XMLの代わりに、サーバーとウエブアプリケーションの間にデータを送信します。\nCMSで作成したサイトコンテンツを保存や管理するために、SquarespaceはJSONを使用しています。",
    [LocalizationKey.ProductDataXmlInfo]: "人やコンピューターが解読できるマークアップ言語です。 XMLのデザイン目的はシンプルで、一般性や有用性が高いツールを 提供します。 データフォーマットはユニコードをサポートするテキスト形式です。",
    [LocalizationKey.ProductImages]: "プロダクト画像 (zipファイル)",

    /*  VISUAL PRICE LIST  */
    [LocalizationKey.VisualPriceList]: "定価",
    [LocalizationKey.ProductCode]: "プロダクトコード",
    [LocalizationKey.ProductDescription]: "プロダクト説明",
    [LocalizationKey.ViewBy]: "表示",
    [LocalizationKey.Found]: "Found",
    [LocalizationKey.Product]: "Product",
    [LocalizationKey.MasterCarton]: "マスターカートン",
    [LocalizationKey.SuggestedPrice]: "希望小売価格",
    [LocalizationKey.Loading]: "読み取り中、少々お待ちください...",
    [LocalizationKey.TechnicalSpecifications]: "スペック・仕様",
    [LocalizationKey.Specification]: "仕様",
    [LocalizationKey.Value]: "価格",
    [LocalizationKey.CountryOfOrigin]: "生産国",
}

export default jaJP;