import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import axios from 'axios';
import { LocalizationKey } from 'helpers/Enums';
import { applicationContext } from 'helpers/services';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';

interface IEdtiTrackingNumberProps {
    productIds: string[]
}

let DownloadSelectedProducts = (props: IEdtiTrackingNumberProps) => {
    let { formatMessage } = useIntl();
    let { enqueueSnackbar } = useSnackbar();

    let appService = useContext(applicationContext);

    let [isOpen, setOpen] = useState(false);


    let downloadImages = async () => {
        try {
            let values = {
                ids: props.productIds,
            }

            await axios.post('/api/DownloadRequest/SaveDownloadRequest', values);
            enqueueSnackbar(formatMessage({ id: LocalizationKey.RequestSent }), { variant: "success" });
            setOpen(false);

        } catch (err) { }
    }


    return (
        <>

            <button type="button" className="btn btn-secondary" onClick={(e) => setOpen(true)}>
                <i className="fa fa-download" /> {formatMessage({ id: LocalizationKey.DownloadSelectedImages })}
            </button>

            <Dialog
                open={isOpen}
                maxWidth="md"
                fullWidth
                onClose={(e, reason) => {
                    if (reason === "escapeKeyDown")
                        setOpen(false)
                }}
            >
                <DialogTitle>{formatMessage({ id: LocalizationKey.DownloadAllImages })}</DialogTitle>

                <DialogContent>
                    <Typography variant="subtitle1">
                        {formatMessage(
                            { id: LocalizationKey.DownloadAllImagesNotes },
                            { emailAddress: <strong>{appService.currentUser?.email.toLowerCase()}</strong> })}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='outlined'
                        color="secondary"
                        onClick={() => setOpen(false)}
                    >
                        {formatMessage({ id: LocalizationKey.Close })}
                    </Button>
                    <Button
                        onClick={() => downloadImages()}
                        variant="contained"
                        color="primary"
                    >
                        {formatMessage({ id: LocalizationKey.Confirm })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DownloadSelectedProducts;
