import { TableContainer, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, Button, Tooltip, Zoom, IconButton } from "@mui/material";
import { DeleteIconButton, EditIconButton } from "components";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { usersContext } from "../UsersContext";
import { applicationContext } from "helpers/services";
import { CopyAll as CopyIcon, PersonAddAlt1Outlined as AdminCreateUserIcon } from '@mui/icons-material';

const Results = () => {
    const { currentUser } = useContext(applicationContext);
    const service = useContext(usersContext);


    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Company</TableCell>
                        {currentUser.isAdmin && <TableCell />}
                        <TableCell align="right">
                            <Button component={Link} variant="outlined" size="small" to="/admin/user/addnew">
                                Add new
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {service.users.map(user =>
                        <TableRow hover key={user.id}>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.firstName} {user.lastName}</TableCell>
                            <TableCell>{user.companyName}{user.extId && user.extId !== '' && ` (${user.extId})`}</TableCell>
                            {currentUser.isAdmin &&
                                <TableCell>
                                    {user.existInCognito === false &&
                                        <Typography variant="caption" color="error">Does not exist in Cognito</Typography>
                                    }
                                </TableCell>
                            }
                            <TableCell align="right">
                                <Tooltip TransitionComponent={Zoom} title={user.existInCognito === false ? 'Create user in cognito' : 'Reset password'}>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={() => service.resendPassword(user.id, user.existInCognito)}
                                    >
                                        <AdminCreateUserIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip TransitionComponent={Zoom} title="Copy">
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        component={Link}
                                        to={`/admin/user/addnew/${user.id}`}
                                    >
                                        <CopyIcon />
                                    </IconButton>
                                </Tooltip>
                                <EditIconButton size="small" link={`/admin/user/edit/${user.id}`} />
                                <DeleteIconButton onClick={() => service.deleteUser(user.id)} />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
                {service.users.length === 0 &&
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={currentUser.isAdmin ? 5 : 4}>
                                <i>No users has been found</i>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                }
            </Table>
        </TableContainer>
    )
}

export default Results;