import { useContext } from "react";
import { visualPriceListContext } from "../VisualPriceListContext";
import TableView from "./TableView";
import GridView from "./GridView";
import { useIntl } from "react-intl";
import { LocalizationKey } from "helpers/Enums";
import { Loading } from "components";


let Results = () => {
    let service = useContext(visualPriceListContext);
    let { formatMessage } = useIntl();

    return (
        <div className="container-fluid special-p">
            {service.isLoading === true && <Loading />}
            {service.isLoading === false &&
                <div className="tabs-container list-products">
                    {service.products.length !== 0 &&
                        <>
                            {service.viewType === 'table' &&
                                <TableView />
                            }
                            {service.viewType === 'grid' &&
                                <GridView />
                            }
                        </>
                    }
                    {service.products.length <= 0 &&
                        <h3 className="no-data">{formatMessage({ id: LocalizationKey.NoResultsFound })}</h3>
                    }
                </div>
            }
        </div>
    );
}

export default Results;